<template>
    <div class="container">
        
        <router-link to="/roles/crear" class="btn btn-success">Crear nuevo</router-link>

        <div class="card mt-3">
            <div class="card-header">
                Roles de Usuarios
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                                            
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="rol in roles" :key="rol.id">                         
                            <td> {{rol.nombre}} </td>                                             

                            <td>          
                                <router-link :to="{name:'Roles/Detalles', params:{id:rol.id}}" class="text-primary" title="Detalles" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name:'Roles/Editar', params:{id:rol.id}}" class="text-primary ml-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a class="text-danger ml-2" v-on:click="borrarRol(rol.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </tr>

                    </tbody> 
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return{
            roles:[], //Lista que almacena los roles optenidos del metodo "consultarRoles"
            token: null, //Almacena los datos del token de seguridad
        }
    },
    created: async function(){
        await this.obtenerToken()
        this.consultarRoles();
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener los roles registrados
         */
        consultarRoles(){
           

            return new Promise ((resolve,reject)=>{
                 fetch('https://prixapi.prixisevents.com/api/roles',{ 
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                 }) 
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{              
                    this.roles = respuestaDatos; 
                    resolve()                           
                })
                .catch(console.log)
            })
        },
        /**
         * Metodo para eliminar un rol
         */
        borrarRol(id){    
            if(confirm('Estás seguro que deseas eliminar el rol')) {

                fetch('https://prixapi.prixisevents.com/api/roles/'+id, // http://prixisapi.net/roles/
                {
                    method:"DELETE",
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    window.location.href='/roles/listar'      
                })
                .catch(console.log)

            }              
        },
    },
    computed:{
        imagenM(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
}
</script>