<template>
    <div class="container">
       
        <div class="card">
            <div class="card-header">
                Modificar articulo
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="actualizarRegistro">
          
                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="rol.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del rol</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Descripcion</label>
                      <input type="text"
                        class="form-control" name="descripcion" v-model="rol.descripcion" value=" " id="descripcion" aria-describedby="helpId" placeholder="Descripcion">
                      <small id="helpId" class="form-text text-muted">Escribe la descripcion del rol</small>
                    </div>

                    <div class="btn-group" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Modificar</button>
                        <router-link to="/roles/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            rol:{} //Objeto que almacena el rol obtenido del metodo "obtenerRol"
        }
    },
    created: async function(){
        await this.obtenerArticulo()
    },
    methods:{
        /**
         * Metodo para obtener un rol registrado
         */
        obtenerArticulo(){
            return new Promise ((resolve,reject)=>{
                fetch('https://prixapi.prixisevents.com/api/roles/'+this.$route.params.id) //http://prixisapi.net/roles/
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.rol = respuestaDatos.msg;
                    resolve();            
                })
                .catch(console.log)
            })
        },
        /**
         * Metodo para actualizar los datos de un rol
         */
        actualizarRegistro(){
            var datosEnviar = {
                id: this.rol.id,
                nombre: this.rol.nombre,
                descripcion: this.rol.descripcion
            }
            /**
             * Validacion de los campos no requeridos
             */
            if(datosEnviar.descripcion == null){
                datosEnviar.descripcion = ""
            }

            fetch('https://prixapi.prixisevents.com/api/roles/'+datosEnviar.id, //http://prixisapi.net/roles/
            {
                method:"PUT",
                headers: {
                'Content-Type': 'application/json',
                 },
                body: JSON.stringify(datosEnviar)
            })
            .then(datosRespuesta=>{              
               window.location.href='/roles/listar'
            })
            .catch(error => console.error(error))
            console.log(error)
        },
    }
}
</script>