<template>
<div>
     <router-link to="/galerias/listar" class="btn btn-danger mb-3">Atras</router-link>

    <form v-on:submit.prevent="subirImagen">
        <div class="mb-3">
            <label for="formFile" class="form-label">Subir archivos</label>
            <input class="form-control" type="file" id="formFile" name="file" @change="fileSelected">
        </div>
        
        <button type="submit" class="btn btn-primary">Subir</button>
    </form>

    <h3>Galeria</h3>

    <div class="row">
        <div v-for="img in listImagenes" :key="img.id" class="card-galery mt-1">
            <img :src="img.direccion" alt="imagen" class="card-img-top imagen-card">
        </div>
    </div>
    
</div> 
</template>

<script>
export default {
    data(){
        return{
            imagen:{}, //Objeto que almacena los datos obtenidos de los inputs
            listImagenes:[] //Lista que almacena las imagenes optenidas del metodo "obtenerImagenes"
        }
    },
    created: async function(){
        await this.obtenerImagenes()
    },
    methods:{
        fileSelected(event){
            this.imagen.file = event.target.files[0]
        },
        subirImagen(){
            var formData = new FormData()
            formData.append('imagen', this.imagen.file)
            formData.append('idGaleria', this.$route.params.id)
            
            //debugger

            fetch('https://prixapi.prixisevents.com/api/galerias/subirImagen',
            {
                method:"POST",
                headers: {
               // 'Content-Type': 'false',
               // 'enctype': 'multipart/form-data',
                },
                body: formData
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
                window.location.href='/galerias/detalles/'+this.$route.params.id
            })
            .catch(error => {
             console.log(error)
            })
        },
        obtenerImagenes(){
            return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/galerias/imagenes/'+this.$route.params.id)
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                    if(typeof respuestaDatos[0].success==='undefined'){
                        this.listImagenes = respuestaDatos;  
                       
                        if(this.listImagenes instanceof Array == false){                          
                            this.listImagenes = []
                        }
                       
                        resolve()                
                    }
                })
                .catch(console.log)
            })
        }
    }
}
</script>

<style scoped>
    .card-galery{
        width: 10rem;
    }
</style>