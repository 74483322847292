<template>
    <div class="container">
        
        <router-link to="/usuarios/crear" class="btn btn-success">Crear nuevo</router-link>

        <div class="card mt-3">
            <div class="card-header">
                Usuarios
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                         
                            <th>Email</th>                           
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="usuario in usuarios" :key="usuario.id">                         
                            <td> {{usuario.name}} </td>                        
                            <td> {{usuario.email}} </td>                         

                            <td>          
                                <router-link :to="{name:'Usuarios/Detalles', params:{id:usuario.id}}" class="text-primary" title="Detalles" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name:'Usuarios/Editar', params:{id:usuario.id}}" class="text-primary ml-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a class="text-danger ml-2" v-on:click="borrarUsuario(usuario.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return{
            usuarios:[], //Lista que almacena los usuarios optenidos del metodo "consultarUsuarios"
            token: null, //Almacena los datos del token de seguridad
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.consultarUsuarios();
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener los usuarios registrados
         */
        consultarUsuarios(){          
            return new Promise ((resolve,reject)=>{
                fetch('https://prixapi.prixisevents.com/api/usuarios',{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{                  
                    this.usuarios = respuestaDatos;                                 
                    resolve();
                })
                .catch(console.log)
            })
        },
        /**
         * Metodo para eliminar un usuario
         */
        borrarUsuario(id){    
            if(confirm('Estás seguro que deseas eliminar el usuario')) {

                fetch('https://prixapi.prixisevents.com/api/usuarios/'+id, //http://prixisapi.net/usuarios/
                {
                    method:"DELETE",
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    window.location.href='/usuarios/listar'      
                })
                .catch(console.log)

            }              
        },
    },
    computed:{
        imagenM(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
}
</script>