<template>
    <div class="container">

        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
                <router-link :to="{name:'Servicios/Crear', params:{id:this.$route.params.id}}" class="btn btn-success">Crear nuevo</router-link>
            </div>
        </div>
        
        <div class="card mt-3">
            <div class="card-header">
                Servicios
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                         
                            <th>Empresa</th>                       
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="servicio in servicios" :key="servicio.id">                         
                            <td> {{servicio.nombreReal.nombre}} </td>    
                            <td> {{servicio.empresa.nombre}} </td>                      
                            <td>          
                                <!-- <router-link :to="{name:'Usuarios/Detalles', params:{id:usuario.id}}" class="text-primary" title="Detalles" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link> -->
                                <router-link :to="{name:'Servicios/Editar', params:{idEmpresa:idEmpresa, id:servicio.id}}" class="text-primary ml-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <router-link :to="{name:'GaleriasServicios', params:{idServicio:servicio.galerias[0].id, idEmpresa:idEmpresa}}" class="text-primary ml-2" title="Galeria" role="button">
                                    <i class="fas fa-images"></i>
                                </router-link>
                                <a class="text-danger ml-2" v-on:click="borrarServicio(servicio.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
                <b-overlay :show="showGeneral" no-wrap></b-overlay>
            </div>
        </div> 
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            token:null,
            servicios:[], //Lista que almacena los servicios optenidos del metodo "consultarServicios"
            idEmpresa:0,
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text: 'Servicios',
                active: true
                }
            ],
            showGeneral:true,
        }
    },
    created:async function(){
        await this.obtenerToken()
        await this.consultarServicios();
        this.idEmpresa = this.$route.params.id
    },
    computed:{
        ...mapState({
            tokenAuth: 'token'
        })
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener los servicios registrados
         */
        async consultarServicios(){
            // return new Promise ((resolve,reject)=>{

            //     if(this.$route.params.id == -1){
            //         fetch('https://prixapi.prixisevents.com/api/servicios',{
            //             headers:{
            //             'Authorization': 'Bearer '+ this.token
            //             }
            //         })
            //         .then(respuesta=>respuesta.json())
            //         .then((respuestaDatos)=>{
            //             if(typeof respuestaDatos){
            //                 if(respuestaDatos.res == '200'){
            //                     this.servicios = respuestaDatos.msg; 
            //                     this.showGeneral = false 
            //                     resolve()    
            //                 }else{
            //                     console.log("Error al obtener los servicios registrados")
            //                     resolve()
            //                 }
                                        
            //             }
            //         })
            //         .catch(console.log)
            //     }else{
            //         fetch('https://prixapi.prixisevents.com/api/empresas/servicios/'+this.$route.params.id,{
            //             headers:{
            //             'Authorization': 'Bearer '+ this.token
            //             }
            //         })
            //         .then(respuesta=>respuesta.json())
            //         .then((respuestaDatos)=>{
            //             if(respuestaDatos.res == '200'){
            //                 this.servicios = respuestaDatos.msg
            //                 this.showGeneral = false
            //                 resolve()                
            //             }else{
            //                 console.log("Error al obtener los servicios registrados")
            //                 resolve()
            //             }
            //         })
            //         .catch(console.log)
            //     }

                      
            // })

            if(this.$route.params.id == -1){
                    var data = await apiLocal.get('/servicios',{
                        headers:{
                            'Authorization': 'Bearer '+ this.token
                        }
                    })
                    var res = await data.data

                    if(res.res == '200'){
                        this.servicios = res.msg; 
                        this.showGeneral = false    
                    }else{
                        console.log("Error al obtener los servicios registrados")
                    }

                }else{
                    var data = await apiLocal.get('/empresas/servicios/' + this.$route.params.id,{
                        headers:{
                            'Authorization': 'Bearer '+ this.token
                        }
                    })
                    var res = await data.data

                    if(res.res == '200'){
                        this.servicios = res.msg
                        this.showGeneral = false               
                    }else{
                        console.log("Error al obtener los servicios registrados")
                    }
                }
        },

        /**
         * Metodo para eliminar un servicio
         */
        async borrarServicio(id){    
            if(confirm('Estás seguro que deseas eliminar el servicio')) {

                // fetch('https://prixapi.prixisevents.com/api/servicios/'+id,
                // {
                //     method:"DELETE",
                //     headers:{
                //         'Authorization': 'Bearer '+ this.token
                //     }
                // })
                // .then(respuesta=>respuesta.json())
                // .then((respuestaDatos)=>{
                //     if(respuestaDatos.res == '200'){
                //         window.location.href='/servicios/listar/' + this.$route.params.id 
                //     }else{
                //         console.log("Error al intentar eliminar el servicio")
                //     }
                         
                // })
                // .catch(console.log)

                var data = await apiLocal.delete('/servicios/' + id,{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                var res = await data.data
                
                if(res.res){
                    // window.location.href='/servicios/listar/' + this.$route.params.id 
                    await this.consultarServicios();
                }else{
                    console.log("Error al intentar eliminar el servicio")
                }

            }              
        },
    }
}
</script>