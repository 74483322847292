<template>
    <div class="container">

        <div class="card">
            <div class="card-header">
                Crear Tipo de Servicio
            </div>
            <div class="card-body pb-5">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="tipoServicio.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del tipo de servicio</small>
                    </div>

                    <div class="form-group mt-3">
                        <label for="">Selecciona la empresa a la que pertenece el tipo de servicio</label>
                        <select class="form-select form-select-sm" required v-model="idEmpresa">
                            <option v-for="empresa in listEmpresas" :key="empresa.id" v-bind:value="empresa.id">
                                {{empresa.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione la empresa</small>
                    </div> 

                    <div class="mb-3">
                        <label for="formFile" required class="form-label">Subir imagen-1</label>
                        <input class="form-control" type="file" id="formFile" name="file" @change="fileSelected">
                    </div>

                    <div class="mb-3">
                        <label for="formFile2" required class="form-label">Subir imagen-2</label>
                        <input class="form-control" type="file" id="formFile2" name="file2" @change="fileSelected2">
                    </div>                                  
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/tiposServicios/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            tipoServicio:{}, //Objeto que almacena los datos optenidos de los inputs
            listEmpresas:[], //Lista que almacena las empresas registradas
            idEmpresa:0 //Almacena el id de la empresa del select
        }
    },
    created: async function(){
        await this.obtenerEmpresas()
    },
    methods:{
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        fileSelected(event){
            this.tipoServicio.imagen = event.target.files[0]
        },
         fileSelected2(event){
            this.tipoServicio.imagen2 = event.target.files[0]
        },
        /**
       * Metodo para guardar un nuevo tipo de servicio
       */
        agregarRegistro(){

            // var datosEnviar = {
            //     nombre: this.tipoServicio.nombre,
            //     id_empresa: this.idEmpresa,           
            // }

            var formData = new FormData()
            formData.append('nombre', this.tipoServicio.nombre)
            formData.append('id_empresa', this.idEmpresa)
            formData.append('imagen', this.tipoServicio.imagen)
            formData.append('imagen2', this.tipoServicio.imagen2)
            
            fetch('https://prixapi.prixisevents.com/api/tipoServicios', //http://prixisapi.net/tiposservicios
            {
                method:"POST",
                headers: {
                // 'Content-Type': 'application/json',
                 },
                body: formData
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
            //    this.tipoServicio = {}
            //    window.location.href='/tiposServicios/listar'
            })
            .catch(error => {
             console.log(error)
            })
        },
        /**
       * Metodo para obtener las empresas registrados
       */
       obtenerEmpresas(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/empresas')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.listEmpresas = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },
    }
}
</script>