<template>
<div>
    <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="#">
            <router-link :to="{name:'Empresas/Principal'}" class="text-white text-decoration-none">
                Prixis Admin
            </router-link>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
            <!-- Usuario simple -->
            <b-nav-item href="#" class="mr-2" v-if="idEmpresa != -1 && acceso(1)">
                <router-link :to="{name:'Servicios/Listar', params:{id:idEmpresa}}" class="text-white text-decoration-none">
                    <i class="fas fa-list mr-2"></i> Servicios
                </router-link>
            </b-nav-item>
            
            <b-nav-item href="#" class="mr-2" v-if="idEmpresa != -1 && acceso(1)">
                <router-link :to="{name:'Paquetes/Listar', params:{id:idEmpresa}}" class="text-white text-decoration-none">
                    <i class="fas fa-dollar-sign mr-2"></i> Paquetes y Precios
                </router-link>
            </b-nav-item>

            <b-nav-item href="#" class="mr-2" v-if="idEmpresa != -1 && acceso(1)">
                <router-link :to="{name:'PersonalizarIndex', params:{id:idEmpresa}}" class="text-white text-decoration-none">
                    <i class="fas fa-palette mr-2"></i> Personalizar
                </router-link>
            </b-nav-item>

            <b-nav-item href="#" class="mr-2" v-if="idEmpresa != -1 && acceso(1)">
                <router-link :to="{name:'Empresa/Editar', params:{id:idEmpresa}}" class="text-white text-decoration-none">
                   <i class="fas fa-building mr-2"></i> Empresa
                </router-link>
            </b-nav-item>

            <!-- Usuario super admin -->
            <b-nav-item href="#" class="mr-2" v-if="acceso(2)">
                <router-link :to="{name:'Roles/Listar'}" class="text-white text-decoration-none">
                   <i class="fas fa-user-shield mr-2"></i> Roles
                </router-link>
            </b-nav-item>

            <b-nav-item href="#" class="mr-2" v-if="acceso(2)">
                <router-link :to="{name:'Usuarios/Listar'}" class="text-white text-decoration-none">
                   <i class="fas fa-users mr-2"></i> Usuarios
                </router-link>
            </b-nav-item>

            <b-nav-item href="#" class="mr-2" v-if="acceso(2)">
                <router-link :to="{name:'TiposServicios/Listar'}" class="text-white text-decoration-none">
                   <i class="fas fa-list-alt mr-2"></i> Tipos de Servicios
                </router-link>
            </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

            <!-- <b-nav-item-dropdown right> -->
            <!-- Using 'button-content' slot -->
            <!-- <template #button-content>
                <span class="text-white">{{usuario.nombre }}</span>
            </template>
            <b-dropdown-item v-on:click="logout()"><i class="fas fa-sign-out-alt icon mr-2"></i>Salir</b-dropdown-item>
            </b-nav-item-dropdown> -->
            <b-nav-item href="#" class="mr-2">
                <a v-on:click="logout()" class="text-white"><i class="fas fa-sign-out-alt icon mr-2"></i>Salir</a>
            </b-nav-item>
        </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</div>
</template>

<script>
import {mapState} from 'vuex'
import {mapMutations} from 'vuex'

export default {
    data(){
        return{
            // idEmpresa:-1 //id de la empresa del usuario autenticado
        }
    },
    computed:{
        ...mapState({
            idEmpresa: 'idEmpresa',
            usuario: 'user'
        })
    },
    methods:{
        logout(){
            this.cerrarSesion()
            this.$router.replace({
                name:'AutenticarUsuario'
            })
        },
        acceso(rol){
            var permiso = false;

            for(var i=0; i < this.usuario.roles.length; i++){
                if(this.usuario.roles[i].id == rol){
                    permiso = true

                    return permiso;
                }
            }

            return permiso;
        },
        ...mapMutations({
            cerrarSesion: 'logout',
        }),
    }
}
</script>