/**
 * Configura axios para consumir API local o publicada
 */

import axios from 'axios'

//API local
const apiPublicada = axios.create({
    baseURL: 'https://prixapi.prixisevents.com/api',
    headers:{

    }
})

//API publicada
const apiLocal = axios.create({
    baseURL: 'http://localhost/prixis/api-prixis/public/api',
    headers:{

    }
})

export {apiPublicada,apiLocal}