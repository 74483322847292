import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'


import HomeView from '../views/HomeView.vue'
import UsuarioSimple from '../views/inicio/UsuarioSimple.vue'

/**
 * Auth
 */
 import RegistrarUsuario from '../views/auth/register.vue'
 import AutenticarUsuario from '../views/auth/login.vue'

/**
 * Roles 
 */
import RolesListar from '../views/roles/Listar.vue'
import RolesCrear from '../views/roles/Crear.vue'
import RolesEditar from '../views/roles/Editar.vue'
import RolesDetalles from '../views/roles/Detalles.vue'

/**
 * Usuarios
 */
import UsuariosListar from '../views/usuarios/Listar.vue'
import UsuariosCrear from '../views/usuarios/Crear.vue'
import UsuariosEditar from '../views/usuarios/Editar.vue'
import UsuariosDetalles from '../views/usuarios/Detalles.vue'

/**
 * Galerias
 */
import GaleriasListar from '../views/galeria/Listar.vue'
import GaleriasCrear from '../views/galeria/Crear.vue'
import GaleriasEditar from '../views/galeria/Editar.vue'
import GaleriasDetalles from '../views/galeria/Detalles.vue'

/**
 * Empresas
 */
import EmpresaPrincipal from '../views/empresa/Principal.vue'
import EmpresasListar from '../views/empresa/Listar.vue'
import EmpresasCrear from '../views/empresa/Crear.vue'
import EmpresasEditar from '../views/empresa/Editar.vue'
import EmpresasCrearSimple from '../views/empresa/CrearSimple.vue'
import EmpresaEditarSimple from '../views/empresa/EditarSimple.vue'


 /**
  * Tipos de Servicios
  */
  import TiposServiciosListar from '../views/tiposServicios/Listar.vue'
  import TiposServiciosCrear from '../views/tiposServicios/Crear.vue'
  import TiposServiciosEditar from '../views/tiposServicios/Editar.vue'
 
  
  /**
  * Servicios 
  */
  import ServiciosListar from '../views/servicios/Listar.vue'
  import ServiciosCrear from '../views/servicios/Crear.vue'
  import ServiciosEditar from '../views/servicios/Editar.vue'

  /**
   * Paquetes y precios
   */
  import PaquetesListar from '../views/paquetes/Listar.vue'
  import PaquetesCrear from '../views/paquetes/Crear.vue'
  import PaquetesEditar from '../views/paquetes/Editar.vue'

  /**
   * Galeria de servicios
   */
  import GaleriaServicioIndex from '../views/galeriaServicio/index.vue'
  import GaleriaServicioCrear from '../views/galeriaServicio/Crear.vue'

  /**
   * Personalizar
   */
  import IndexPersonalizar from '../views/personalizar/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[

      {
        path: '/home',
        name: 'UserSimple',
        component: UsuarioSimple,
        beforeEnter:(to, from, next) => {
          return (store.state.hasEmpresa) ? next({name:'Empresas/Principal'}) : next()
        }
      },
      /**
       * Roles
       */
      {
        path: '/roles/listar',
        name: 'Roles/Listar',
        component: RolesListar
      },
      {
        path: '/roles/crear',
        name: 'Roles/Crear',
        component: RolesCrear
      },
      {
        path: '/roles/editar/:id',
        name: 'Roles/Editar',
        component: RolesEditar
      },
      {
        path: '/roles/detalles/:id',
        name: 'Roles/Detalles',
        component: RolesDetalles
      },

      /**
       * Usuarios
       */
      {
        path: '/usuarios/listar',
        name: 'Usuarios/Listar',
        component: UsuariosListar
      },
      {
        path: '/usuarios/crear',
        name: 'Usuarios/Crear',
        component: UsuariosCrear
      },
      {
        path: '/usuarios/editar/:id',
        name: 'Usuarios/Editar',
        component: UsuariosEditar
      },
      {
        path: '/usuarios/detalles/:id',
        name: 'Usuarios/Detalles',
        component: UsuariosDetalles
      },

      /**
       * Galerias
       */
      {
        path: '/galerias/listar',
        name: 'Galerias/Listar',
        component: GaleriasListar
      },
      {
        path: '/galerias/crear',
        name: 'Galerias/Crear',
        component: GaleriasCrear
      },
      {
        path: '/galerias/editar/:id',
        name: 'Galerias/Editar',
        component: GaleriasEditar
      },
      {
        path: '/galerias/detalles/:id',
        name: 'Galerias/Detalles',
        component: GaleriasDetalles
      },

      /**
       * Empresas
       */
      {
        path: '/empresas/principal',
        name: 'Empresas/Principal',
        component: EmpresaPrincipal
      },
      {
        path: '/empresas/listar',
        name: 'Empresas/Listar',
        component: EmpresasListar
      },
      {
        path: '/empresas/crear',
        name: 'Empresas/Crear',
        component: EmpresasCrear
      },
      {
        path: '/empresas/editar/:id',
        name: 'Empresas/Editar',
        component: EmpresasEditar
      },
      {
        path: '/empresa/crear',
        name: 'Empresa/Crear',
        component: EmpresasCrearSimple
      },
      {
        path: '/empresa/editar/:id',
        name: 'Empresa/Editar',
        component: EmpresaEditarSimple
      },

      /**
       * Tipos de Servicios
       */
      {
        path: '/tiposServicios/listar',
        name: 'TiposServicios/Listar',
        component: TiposServiciosListar
      },
      {
        path: '/tiposServicios/crear',
        name: 'TiposServicios/Crear',
        component: TiposServiciosCrear
      },
      {
        path: '/tiposServicios/editar/:id',
        name: 'TiposServicios/Editar',
        component: TiposServiciosEditar
      },

      /**
       * Servicios
       */
      {
        path: '/servicios/listar/:id',
        name: 'Servicios/Listar',
        component: ServiciosListar
      }, 
      {
        path: '/servicios/crear/:id',
        name: 'Servicios/Crear',
        component: ServiciosCrear
      },
      {
        path: '/servicios/editar/:idEmpresa/:id',
        name: 'Servicios/Editar',
        component: ServiciosEditar
      },  

      /**
       * Paquetes y precios
       */
      {
        path: '/paquetes/listar/:id',
        name: 'Paquetes/Listar',
        component: PaquetesListar
      }, 
      {
        path: '/paquetes/crear/:idEmpresa/:id',
        name: 'Paquetes/Crear',
        component: PaquetesCrear
      },
      {
        path: '/paquetes/editar/:id',
        name: 'Paquetes/Editar',
        component: PaquetesEditar
      },

      /**
       * Galeria de Servicios
       */
      {
        path: '/galeriaServicios/:idServicio/:idEmpresa',
        name: 'GaleriasServicios',
        component: GaleriaServicioIndex
      },
      {
        path: '/galeriaServicios/crear/:idServicio/:idEmpresa',
        name: 'GaleriasServicios/Crear',
        component: GaleriaServicioCrear
      }, 

      /**
       * Personalizar
       */
       {
        path: '/personalizar/:id',
        name: 'PersonalizarIndex',
        component: IndexPersonalizar
      },

    ]
  },
  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  /**
   * Auth
   */
  {
    path: '/login',
    name: 'AutenticarUsuario',
    component: AutenticarUsuario
  },
  {
    path: '/registrar-usuario',
    name: 'RegistrarUsuario',
    component: RegistrarUsuario
  },

  
   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
