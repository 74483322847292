<template>
    <div class="container">

        <div class="card">
            <div class="card-header">
                Crear Rol
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="rol.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del rol</small>
                    </div>            

                    <div class="form-group mt-3">
                      <label for="">Descripcion</label>
                      <input type="text"
                        class="form-control" name="descripcion" v-model="rol.descripcion" value=" " id="descripcion" aria-describedby="helpId" placeholder="Descripcion">
                      <small id="helpId" class="form-text text-muted">Escribe la descripcion del rol</small>
                    </div>
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/roles/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            rol:[] //Objeto que almacena los datos optenidos de los inputs
        }
    },
    methods:{
        /**
       * Metodo para guardar un nuevo rol
       */
        agregarRegistro(){

            var datosEnviar = {
                nombre: this.rol.nombre,
                descripcion: this.rol.descripcion
            }
            /**
             * Validacion de los campos no requeridos
             */
            if(datosEnviar.descripcion == null){
                datosEnviar.descripcion = ""
            }
            console.log(datosEnviar)
            fetch('https://prixapi.prixisevents.com/api/roles', //http://prixisapi.net/roles
            {
                method:"POST",
                headers: {
                'Content-Type': 'application/json',
                 },
                body: JSON.stringify(datosEnviar)
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
               this.rol = {}
               window.location.href='/roles/listar'
            })
            .catch(error => {
             console.log(error)
            })
        },
    }
}
</script>