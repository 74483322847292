<template>
    <div class="container">
        <h4 class="mb-4">Detalles del usuario</h4>

        <p>Nombre: {{usuario.nombre}}</p>
        <p>Email: {{usuario.email}}</p>

        <h5 class="mt-3">Roles:</h5>
        <div class="check-group">
            <div class="form-check" v-for="rol in roles" :key="rol.id">
                <input class="form-check-input" type="checkbox" :id="rol.id" :value="rol.id" v-model="nuevosRoles">
                <label class="form-check-label" :for="rol.id">
                    {{rol.nombre}}
                </label>
            </div>
        </div>  

        <div class="d-flex mt-5">
            <router-link to="/usuarios/listar" class="btn btn-danger mx-2">Volver</router-link>
            <a class="btn btn-primary mx-2" v-on:click="actualizarRolesUsuario()" title="ActualizarRoles" role="button">Actualizar Roles</a>           
        </div>
        

    </div>
</template>

<script>
export default {
    data(){
        return{
            usuario:{}, //Objeto que almacena el usuario obtenido del metodo "obtenerUsuario"
            roles:[], //Lista que almacena los roles, se especifica los que contiene el usuario y los que no.
            nuevosRoles:[] //Lista que almacena los roles nuevos que se le quieren otorgar al usuario
        }
    },
    created: async function(){
        await this.obtenerUsuario()
        await this.obtenerRoles()
    },
    methods:{
        /**
         * Metodo para obtener un usuario registrado
         */
        obtenerUsuario(){

            return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/usuarios/'+this.$route.params.id)
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.usuario = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },
        /**
         * Metodo para obtener todos los roles 
         */
        obtenerRoles(){
            return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/roles')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                    if(typeof respuestaDatos[0].success==='undefined'){
                        var rolesRes = respuestaDatos;  
                        this.comprobarRolesAsignados(rolesRes)
                        resolve()                
                    }
                })
                .catch(console.log)
            })
        },
        /**
         * Metodo para los roles que estan asignados al usuario
         */
        comprobarRolesAsignados(roles){                  
            var sizeRolesUser =  this.usuario.roles.length; //Almacena en una variable el tamaño de la lista de roles del usuario           
            for(var i=0; i<roles.length; i++){
                roles[i].asignado = false;               
                for(var j=0; j<sizeRolesUser; j++){
                    if(roles[i].id == this.usuario.roles[j].id){
                        roles[i].asignado = true;
                        this.nuevosRoles.push(roles[i].id)
                        break; 
                    }
                }
            }

            this.roles = roles;          
        },
        /**
         * Metodo para los roles que estan asignados al usuario
         */
       async actualizarRolesUsuario(){
            //Listas que contienen los roles que se desean añadir y quitar, hacen referencia a la lista nuevosRoles
            var rolesAnnadir = []
            var rolesEliminar = []

            /**
             * Busca los roles que se van añadir, descarta los que ya existen
             */
            for(var i=0; i<this.nuevosRoles.length; i++){
                var existe = false
                for(var j=0; j<this.usuario.roles.length; j++){                  
                    if(this.nuevosRoles[i] == this.usuario.roles[j].id){
                        existe = true
                        break;
                    }
                }
                if(!existe){
                    rolesAnnadir.push(this.nuevosRoles[i])
                }
            }

            /**
             * Busca los roles que se van eliminar, son los que correspondian al usuario y ya no estan en la lista de nuevos roles
             */
            for(var i=0; i<this.usuario.roles.length; i++){
                var existe = false
                for(var j=0; j<this.nuevosRoles.length; j++){                  
                    if(this.usuario.roles[i].id == this.nuevosRoles[j]){
                        existe = true
                        break;
                    }
                }
                if(!existe){
                    rolesEliminar.push(this.usuario.roles[i].id)
                }
            }

            await this.annadirRoles(rolesAnnadir)
            await this.eliminarRoles(rolesEliminar)

        },

        /**
         * Metodo para annadir roles al usuario
         */
        annadirRoles(listRoles){
            return new Promise ((resolve,reject)=>{

                var datosEnviar = {
                idUsuario: this.usuario.id,
                roles: listRoles                           
                }

                console.log(datosEnviar)
            
                fetch('https://prixapi.prixisevents.com/api/usuarios/asignarRoles',
                {
                    method:"POST",
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(datosEnviar)
                })
                .then(respuesta=>respuesta.json())
                .then(datosRespuesta=>{
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                })
            })
        },

        /**
         * Metodo para eliminar roles al usuario
         */
        eliminarRoles(listRoles){
            return new Promise ((resolve,reject)=>{

                var datosEnviar = {
                idUsuario: this.usuario.id,
                roles: listRoles                           
                }

                console.log(datosEnviar)
            
                fetch('https://prixapi.prixisevents.com/api/usuarios/eliminarRoles',
                {
                    method:"POST",
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(datosEnviar)
                })
                .then(respuesta=>respuesta.json())
                .then(datosRespuesta=>{              
                resolve()
                })
                .catch(error => {
                console.log(error)
                })
            })
        }

        
    }
}
</script>