<template>
    <div class="">
        <div class="m-5 d-flex justify-content-center">
            <div class="card login-card">
                <div class="card-body">
                    <form v-on:submit.prevent="login">

                        <h3 class="text-center">Prixis</h3>

                        <div class="form-group mt-3">
                        <label for="">Correo:</label>
                        <input type="text"
                            class="form-control" required name="correo" v-model="usuario.email" value=" " id="correo" aria-describedby="helpId" placeholder="">
                        <small id="helpId" class="form-text text-muted">Escriba su correo de usuario</small>
                        </div>            

                        <div class="form-group mt-3">
                        <label for="">Contraseña:</label>
                        <input type="text"
                            class="form-control" required name="password" v-model="usuario.password" value=" " id="password" aria-describedby="helpId" placeholder="">
                        <small id="helpId" class="form-text text-muted">Escriba su contraseña</small>
                        </div>

                        <hr>
                        <div class="form-group mt-3">
                            <p>No tiene una cuenta?<router-link to="/registrar-usuario" class="btn btn-link">Regístrese</router-link> </p>
                        </div> 
            
                        <div class="mt-4" role="group" aria-label="">
                            <button type="submit" class="btn btn-success">Entrar</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mapState} from 'vuex'

export default {
    data(){
        return{
            usuario:{} //Objeto que almacena los datos del usuario
        }
    },
    computed:{
        ...mapState({
            userAuth: 'user',
            existeEmpresa: 'hasEmpresa'
        })
    },
    methods:{
        ...mapActions({
            signIn: 'logIn',
        }),
        async login(){
            var credenciales = {
                email: this.usuario.email,
                password: this.usuario.password
            }

            // this.signIn(credenciales).then(()=>{
            //     if(this.userAuth != null){
            //         this.$router.replace({
            //          name:'home'
            //         })
            //     }
            // })

            var data = await this.signIn(credenciales)

            if(this.userAuth != null){
                this.$router.replace({
                    name:'home'
                })
            }
            else{
                if(data.res == 401){
                var msg = data.message
                this.makeToast(msg)
                }
                else if(data.res == 403){
                    var msg = data.message
                    this.makeToast(msg)
                }
            }
        },

        /**
         * Mensajes de alerta
         */
        makeToast(msg) {
            this.$bvToast.toast(msg, {
            title: 'Error de autenticación',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger'
            })
        }
    }
}
</script>

<style scoped>
.login-card{
    width: 50%;
}

@media (max-width: 767px){
    .login-card{
        width: 100%;
    }
}
</style>