<template>
    <div class="container pb-5">

        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>

        <div class="card mb-5 pb-4">
            <div class="card-header">
                Actualizar Paquete
            </div>
            <div class="card-body pb-5">
                <form v-on:submit.prevent="actualizarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="paquete.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del paquete</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Precio:</label>
                      <input type="text"
                        class="form-control" required name="precio" v-model="paquete.precio" value=" " id="precio" aria-describedby="helpId" placeholder="Precio">
                      <small id="helpId" class="form-text text-muted">Escribe el precio del paquete</small>
                    </div>

                    <!-- <div class="form-group mt-3">
                        <label for="">Selecciona el la empresa</label>
                        <select class="form-control form-select-sm" v-model="paquete.id_empresa">
                            <option v-for="empresa in empresas" :key="empresa.id" v-bind:value="empresa.id">
                                {{empresa.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione la empresa</small>
                    </div> -->

                    <div class="border p-3">
                        <p>Servicios ofrecidos en el paquete</p>
                        <div class="mt-3" v-if="listServicios.length > 0">
                            <div class="form-group mt-1 ml-3" v-for="(servicio, index) in listServicios" :key="servicio.id">
                            <label for="">servicio - {{index+1}}:</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" required name="precio" v-model="listServicios[index].valor" value=" " id="servicio" aria-describedby="helpId" placeholder="Servicio">
                                <div class="input-group-append">
                                    <button class="btn btn-danger" v-on:click.prevent="eliminaServicio(index)"><i class="fas fa-trash"></i></button>
                                </div>
                            </div>
                            </div>        
                        </div>

                        <button class="btn btn-link mt-1 ml-3" v-on:click.prevent="nuevoServicio">+ Añadir servicio al paquete</button>

                    </div>
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Modificar</button>
                        <router-link :to="{ name: 'Paquetes/Listar', params:{id: this.$route.params.idEmpresa}}" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            token:null,
            paquete:{}, //Objeto que almacena los datos optenidos de los inputs
            listServicios: [], //Lista que almacena los distintos servicios ofrecidos que se introducen en los inputs
            empresas:[], //Lista que almacena las empresas registradas
            idEmpresa:0, //Id de la empresa
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text:'Paquetes y Precios',
                to: {name: 'Paquetes/Listar', params:{id:this.$route.params.idEmpresa}}
                },
                {
                text: 'Actualizar',
                active: true
                }
            ]
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.obtenerPaquete()
        // await this.obtenerEmpresas()
    },
    computed:{
        ...mapState({
            tokenAuth: 'token'
        })
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener un paquete registrado por su id
         */
        async obtenerPaquete(){
            // return new Promise ((resolve,reject)=>{
            //     fetch('https://prixapi.prixisevents.com/api/paquete/'+this.$route.params.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{

            //         if(respuestaDatos.res == '200'){
            //             this.paquete = respuestaDatos.msg;
            //             this.separarDescripcion(this.paquete.descripcion)
            //             resolve();    
            //         }else{
            //             console.log("Error al obtener los datos del paquete")
            //             resolve()
            //         }
            //     })
            //     .catch(console.log)
            // })

            var data = await apiLocal.get('/paquete/'+this.$route.params.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.paquete = res.msg;
                this.separarDescripcion(this.paquete.descripcion)   
            }else{
                console.log("Error al obtener los datos del paquete")
            }
        },
        /**
       * Metodo para obtener las empresas registradas
       */
       obtenerEmpresas(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/empresas',{
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.empresas = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },
        /**
       * Metodo para actualizar un Paquete
       */
        async actualizarRegistro(){
            this.paquete.descripcion = ""
            for(var i=0; i<this.listServicios.length; i++){
                this.paquete.descripcion += this.listServicios[i].valor + "\n"
            }

            var datosEnviar = {
                nombre: this.paquete.nombre,
                descripcion: this.paquete.descripcion,
                precio: this.paquete.precio,
                id_empresa: this.$route.params.idEmpresa        
            }

            // fetch('https://prixapi.prixisevents.com/api/paquetes/'+ this.$route.params.id,
            // {
            //     method:"PUT",
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer '+ this.token
            //     },
            //     body: JSON.stringify(datosEnviar)
            // })
            // .then(respuesta=>respuesta.json())
            // .then(datosRespuesta=>{
            //    if(datosRespuesta.res == '201'){
            //         this.paquete = {}
            //         window.location.href='/paquetes/listar/' + this.$route.params.idEmpresa
            //     }else if(datosRespuesta.res == '400'){
            //         console.log(datosRespuesta.msg)
            //     }else{
            //         console.log("Ocurrio un error a la hora de actualizar los datos del paquete")
            //     }
            // })
            // .catch(error => {
            //  console.log(error)
            // })

            var data = await apiLocal.put('/paquetes/'+ this.$route.params.id, datosEnviar,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '201'){
                this.paquete = {}
                window.location.href='/paquetes/listar/' + this.$route.params.idEmpresa
            }else if(res.res == '400'){
                console.log(res.msg)
            }else{
                console.log("Ocurrio un error a la hora de actualizar los datos del paquete")
            }
        },
        /**
         * Añade un campo nuevo de servicio
         */
        nuevoServicio(){
            this.listServicios.push({id:this.listServicios.length+1})
        },
        /**
         * Elimina un campo de servicio
         */
        eliminaServicio(index){
            this.listServicios.splice(index,1)
        },
        /**
         * Metodo para separar la descripcion en un arreglo, elemento de separacion /n
         */
        separarDescripcion(){
            var index = this.paquete.descripcion.search('\n')
            var i = 1

            while(index != -1 && this.paquete.descripcion.length > 0){
                this.listServicios.push({id:i, valor:this.paquete.descripcion.substring(0,index)})
                this.paquete.descripcion = this.paquete.descripcion.slice(index+1)
                index = this.paquete.descripcion.search('\n')
                i++
            }

            if(this.paquete.descripcion.length > 0){
                index = this.paquete.descripcion.length
                this.listServicios.push({id:i, valor:this.paquete.descripcion.substring(0,index)})
                this.paquete.descripcion = this.paquete.descripcion.slice(index)
            }
        }
    }
}
</script>