<template>
    <div class="container">
        
        <router-link to="/empresas/crear" class="btn btn-success">Crear nuevo</router-link>

        <div class="card mt-3">
            <div class="card-header">
                Empresas
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                         
                            <th>Director</th>
                            <th>Imagen</th>                           
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="empresa in empresas" :key="empresa.id">                         
                            <td> {{empresa.nombre}} </td>                        
                            <td> {{empresa.director.nombre}} </td>
                            <td><img :src="'https://prixapi.prixisevents.com'+empresa.imagen" alt="imagen-empresa" width="50px" height="50px"></td>                         

                            <td>          
                                <router-link :to="{name:'Paquetes/Listar', params:{id:empresa.id}}" class="text-primary" title="Paquetes y Precios" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name:'Empresas/Editar', params:{id:empresa.id}}" class="text-primary ml-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a class="text-danger ml-2" v-on:click="borrarEmpresa(empresa.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    data(){
        return{
            empresas:[], //Lista que almacena las empresas optenidas del metodo "consultarEmpresas"
            useRol:'' //Almacena el rol del usuario 
        }
    },
    created:function(){
        this.userRol = 'simple'
        this.idUser = 1
        this.consultarEmpresas();
    },
    methods:{
        /**
         * Metodo para obtener los usuarios registrados
         */
        consultarEmpresas(){
            if(this.userRol == 'simple'){
                fetch('https://prixapi.prixisevents.com/api/empresas/usuario/'+this.idUser)
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    if(typeof respuestaDatos[0].success==='undefined'){
                        this.empresas = respuestaDatos;                  
                    }
                })
                .catch(console.log)
            }else{
                fetch('https://prixapi.prixisevents.com/api/empresas') //http://prixisapi.net/empresas
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    if(typeof respuestaDatos[0].success==='undefined'){
                        this.empresas = respuestaDatos;                  
                    }
                })
                .catch(console.log)
            }
            
        },
        /**
         * Metodo para eliminar una empresa
         */
        borrarEmpresa(id){    
            if(confirm('Estás seguro que deseas eliminar la empresa')) {

                fetch('https://prixapi.prixisevents.com/api/empresas/'+id, //http://prixisapi.net/empresas/
                {
                    method:"DELETE",
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    window.location.href='/empresas/listar'      
                })
                .catch(console.log)

            }              
        },
    }
}
</script>