import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery'
import 'popper.js'
import 'bootstrap'
import './assets/app.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// Variables globales
Vue.prototype.$apiLocalhost = "https://prixapi.prixisevents.com";
//Vue.prototype.$apiLocalhost = "http://localhost/prixis/api-prixis/public";

//URL base 
//Pulbicada: https://empresapanel.prixisevents.com/ 
//Local: http://localhost:8081/ 
Vue.prototype.$baseURL = "https://empresapanel.prixisevents.com/";
//Vue.prototype.$baseURL = "http://localhost:8081/";

//URL base para imagenes
//Pulbicada: https://prixapi.prixisevents.com
//Local: http://localhost/prixis/api-prixis/public 
Vue.prototype.$URLImagenes = "https://prixapi.prixisevents.com";
//Vue.prototype.$URLImagenes = "http://localhost/prixis/api-prixis/public";

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
