<template>
    <div class="">
        <div class="m-5 d-flex justify-content-center">
            <div class="card w-50">
                <div class="card-body">
                    <form v-on:submit.prevent="agregarRegistro">

                        <h3 class="text-center">Regístrate en Prixis</h3>

                        <div class="form-group mt-3">
                            <label for="">Nombre:</label>
                            <input type="text"
                            class="form-control" required name="nombre" v-model="usuario.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                            <small id="helpId" class="form-text text-muted">Escribe el nombre del usuario</small>
                        </div>            

                        <div class="form-group mt-3">
                            <label for="">Correo</label>
                            <input type="text"
                            class="form-control" required name="email" v-model="usuario.email" value=" " id="email" aria-describedby="helpId" placeholder="Correo">
                            <small id="helpId" class="form-text text-muted">Escribe el correo del usuario</small>
                        </div>

                        <div class="form-group mt-3">
                            <label for="">Contraseña</label>
                            <input type="password"
                            class="form-control" required name="password" v-model="usuario.password" value=" " id="password" aria-describedby="helpId" placeholder="Contraseña">
                            <small id="helpId" class="form-text text-muted">Escribe la contraseña del usuario</small>
                        </div>

                        <div class="form-group mt-3">
                            <label for="">Confirmar Contraseña</label>
                            <input type="password"
                            class="form-control" required name="password" v-model="usuario.password_confirmation" value=" " id="password" aria-describedby="helpId" placeholder="Contraseña">
                            <small id="helpId" class="form-text text-muted">Escribe la contraseña del usuario</small>
                        </div>
                
                        <div class="btn-group mt-4" role="group" aria-label="">
                            <button type="submit" class="btn btn-success">Registrar</button>
                            <router-link to="/login" class="btn btn-danger">Cancelar</router-link>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            usuario:{} //Objeto que almacena los datos optenidos de los inputs
        }
    },
    methods:{
        /**
       * Metodo para guardar un nuevo usuario
       */
        async agregarRegistro(){

            var datosEnviar = {
                name: this.usuario.nombre,
                password: this.usuario.password,
                email: this.usuario.email,
                password_confirmation: this.usuario.password_confirmation           
            }
            
            // fetch('https://prixapi.prixisevents.com/api/auth/signup',
            // {
            //     method:"POST",
            //     headers: {
            //     'Content-Type': 'application/json',
            //      },
            //     body: JSON.stringify(datosEnviar)
            // })
            // .then(respuesta=>respuesta.json())
            // .then(datosRespuesta=>{
            //    this.usuario = {}
            //    this.$router.replace({
            //         name:'AutenticarUsuario'
            //     })
            // })
            // .catch(error => {
            //  console.log(error)
            // })

            var data = await apiLocal.post('/auth/signup',datosEnviar,{
                headers:{
                   'Content-Type': 'application/json'
                }
            })
            var res = await data.data

            this.usuario = {}
            this.$router.replace({
                name:'AutenticarUsuario'
            })
        },
    }
}
</script>