<template>
    <div class="container">

        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
                  <router-link :to="{name:'Paquetes/Crear', params:{id:this.$route.params.id}}" class="btn btn-success">Crear nuevo</router-link>
            </div>
        </div>
        
        <div class="card mt-3">
            <div class="card-header">
                Paquetes y Precios
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                         
                            <th>Empresa</th>
                            <th>Precio</th>                           
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="paquete in paquetes" :key="paquete.id">                         
                            <td> {{paquete.nombre}} </td>                        
                            <td> {{paquete.empresa.nombre}} </td>
                            <td> {{paquete.precio}} </td>

                            <td>          
                                <!-- <router-link :to="{name:'Usuarios/Detalles', params:{id:usuario.id}}" class="text-primary" title="Detalles" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link> -->
                                 <router-link :to="{name:'Paquetes/Editar', params:{idEmpresa:idEmpresa, id:paquete.id}}" class="text-primary ml-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link> 
                                <a class="text-danger ml-2" v-on:click="borrarPaquete(paquete.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
                <b-overlay :show="show" no-wrap></b-overlay>
            </div>
        </div> 
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            token:null,
            paquetes:[], //Lista que almacena los paquetes optenidos del metodo "consultarPaquetes"
            idEmpresa:0,
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text: 'Paquetes y Precios',
                active: true
                }
            ],
            show:true
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.consultarEmpresas()
        this.idEmpresa = this.$route.params.id
    },
    computed:{
        ...mapState({
            tokenAuth: 'token'
        })
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        async consultarEmpresas(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/paquetes/'+ this.$route.params.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{
            //             if(respuestaDatos.res == '200'){
            //                 this.paquetes = respuestaDatos.msg; 
            //                 this.show = false 
            //                 resolve()    
            //             }else{
            //                 console.log("Error al obtener los paquetes registrados")
            //                 resolve()
            //             }                
            //     })
            //     .catch(console.log)           
            // })

            var data = await apiLocal.get('/paquetes/'+ this.$route.params.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.paquetes = res.msg; 
                this.show = false 
            }else{
                console.log("Error al obtener los paquetes registrados")
            }     
        },
        /**
         * Metodo para eliminar un paquete
         */
        async borrarPaquete(id){    
            if(confirm('Estás seguro que deseas eliminar el paquete')) {

                // fetch('https://prixapi.prixisevents.com/api/paquetes/'+id,
                // {
                //     method:"DELETE",
                //     headers:{
                //         'Authorization': 'Bearer '+ this.token
                //     }
                // })
                // .then(respuesta=>respuesta.json())
                // .then((respuestaDatos)=>{
                //     window.location.href='/paquetes/listar/' + this.$route.params.id     
                // })
                // .catch(console.log)

                var data = await apiLocal.delete('/paquetes/' + id,{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                var res = await data.data

                await this.consultarEmpresas()
            }              
        },
    }
}
</script>