<template>
    <div class="container mb-5 pb-5">
        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>

        <h1>Agregar Imagen Al Servicio</h1>

        <form v-on:submit.prevent="agregarRegistro">
            <div class="custom-file mt-3">
                <label for="customFile" required class="custom-file-label">Subir imagen</label>
                <input class="custom-file-input" type="file" multiple="multiple" id="customFile" name="file" @change="fileSelected">
            </div>

            <!-- <figure class="mt-3" v-if="imagenMiniatura != ''">
                <img alt="" :src="imagenM" height="400" width="auto">
            </figure> -->

            <div class="d-flex mt-3 flex-wrap">
                <figure v-for="(file,index) in imagenPrevisualizar" :key="index" class="mx-2">
                    <img :src="file" alt="" id="imagenPrevisualizar" class="bg-danger">
                </figure>
            </div>  

            <button type="submit" class="btn btn-success mt-3">Subir Imagen</button>
        </form>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return{
            token:null,
            imagen:{}, //Objeto que almacena los datos obtenidos de los inputs
            imagenMiniatura:'', //Hace referencia a la previsualizacion de la imagen seleccionada
            imagenPrevisualizar:[],
            files:[],
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text:'Servicios',
                to: {name: 'Servicios/Listar', params:{id:this.$route.params.idEmpresa}}
                },
                {
                text:'Galería',
                to: {name: 'GaleriasServicios', params:{idServicio:this.$route.params.idServicio,idEmpresa:this.$route.params.idEmpresa}}
                },
                {
                text: 'Agragar Imagen',
                active: true
                }
            ]
        }
    },
    created: async function(){
        await this.obtenerToken()
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        async fileSelected(event){
            this.imagen = event.target.files

            var blob
            var blobMin

            for(var i = 0; i<this.imagen.length; i++){
                blob = await this.comprimirImagen(this.imagen[i])
                blobMin = await this.comprimirImagenMiniaturas(this.imagen[i])
                this.imagenPrevisualizar.push(URL.createObjectURL(blobMin))
                this.files.push(blob)
            }
        },
        /**
         * Metodo para reducir el tamaño de las imagenes seleccionadas
         */
        comprimirImagen(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 0
                    var alto = 0

                    if(imagen.height > imagen.width){
                        ancho = 283
                        alto = 400
                    }else if(imagen.height < imagen.width){
                        ancho = 400 //400
                        alto = 283 //283
                    }else{
                        ancho = 250 //250
                        alto = 250
                    }

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
        comprimirImagenMiniaturas(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 0
                    var alto = 0

                    if(imagen.height > imagen.width){
                        ancho = 141
                        alto = 200
                    }else if(imagen.height < imagen.width){
                        ancho = 200
                        alto = 141
                    }else{
                        ancho = 125
                        alto = 125
                    }

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
        /**
       * Metodo para guardar imagenes a la galeria
       */
        agregarRegistro(){

            for(var i=0; i<this.files.length; i++){

                var formData = new FormData()
                formData.append('id_galeria', this.$route.params.idServicio)
                formData.append('imagen', this.files[i])

                fetch('https://prixapi.prixisevents.com/api/galeriaServicio',
                {
                    method:"POST",
                    headers: {
                        'Authorization': 'Bearer '+ this.token
                    },
                    body: formData
                })
                .then(respuesta=>respuesta.json())
                .then(datosRespuesta=>{
                    if(respuestaDatos.res == '201'){
                        window.location.href='/galeriaServicios/' + this.$route.params.idServicio + '/' + this.$route.params.idEmpresa                           
                    }else{
                        console.log("Error al intentar subir a la galería las imagenes")
                    }
                })
                .catch(error => {
                 console.log(error)
                })
            }
            this.imagen = []
        },
    },
    computed:{
        imagenM(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
}
</script>