<template>
    <div class="container pb-5 mb-5">
        <div class="card" v-if="empresa != null">
            <div class="card-body p-4">
                <div class="detalles d-flex">
                    <div class="logo-empresa">
                        <img :src="directorioImagen + empresa.imagen" class="rounded" alt="imagen-empresa">
                    </div>
                    <div class="descripcion ml-5 pt-4">
                        <h3>{{empresa.nombre}}</h3>
                        <h5>Director: {{empresa.director.name}}</h5>
                        <!-- <p class="mb-0">Descripcion:</p>
                        <div class="descripcion-empresa">
                            <p class="w-75 mt-0">{{empresa.descripcion}}</p>
                        </div> -->
                        <b-button variant="link" class="p-0 mt-3 text-danger" v-on:click="eliminarEmpresa(empresa.id)"><i class="fas fa-trash mr-2"></i>Eliminar Empresa</b-button>
                    </div>
                    <div class="options ml-auto d-flex flex-column">
                        <h6 class="ml-0">Opciones</h6>
                        <router-link :to="{name:'Servicios/Listar', params:{id:empresa.id}}" class="btn btn-link text-left pl-0">
                            <i class="fas fa-list mr-2"></i> Servicios
                        </router-link>
                        <router-link :to="{name:'Paquetes/Listar', params:{id:empresa.id}}" class="btn btn-link text-left pl-0">
                            <i class="fas fa-dollar-sign mr-2"></i> Paquetes y Precios
                        </router-link>
                        <router-link :to="{name:'Empresa/Editar', params:{id:empresa.id}}" class="btn btn-link text-left pl-0">
                            <i class="fas fa-building mr-2"></i> Empresa
                        </router-link>
                        <a href="#redes-sociales" class="btn btn-link text-left pl-0"><i class="fas fa-users mr-2"></i> Redes Sociales</a>
                        <router-link :to="{name:'PersonalizarIndex', params:{id:empresa.id}}" class="btn btn-link text-left pl-0">
                            <i class="fas fa-palette mr-2"></i> Personalización
                        </router-link>
                    </div>
                </div>
                <hr>
                <div class="contenido pl-5">

                    <div class="descripcion-empresa">
                        <h3>Descripción</h3>
                        <p>{{empresa.descripcion}}</p>
                    </div>

                    <hr>

                    <div class="servicos">
                        <b-overlay :show="showServicios">
                        <div class="title d-flex justify-content-between mb-3">
                            <h3>Servicios</h3>
                            <router-link :to="{name:'Servicios/Listar', params:{id:empresa.id}}" class="btn-destock btn btn-primary" title="Paquetes y Precios" role="button">
                                Actualizar
                            </router-link>
                        </div>
                        <ul v-if="servicios.length>0">
                            <li v-for="servicio in servicios" :key="servicio.id">
                                {{servicio.nombreReal.nombre}}
                            </li>
                        </ul>
                        <p v-if="servicios.length<=0">No hay servicios registrados</p>
                        <router-link :to="{name:'Servicios/Listar', params:{id:empresa.id}}" class="btn-movil btn btn-primary mt-5" title="Paquetes y Precios" role="button">
                            Actualizar
                        </router-link> 
                        </b-overlay>  
                    </div>

                    <hr>

                    <div class="paquetes">
                        <b-overlay :show="showPaquetes">
                        <div class="title d-flex justify-content-between mb-3">
                            <h3>Paquetes y Precios</h3>
                            <router-link :to="{name:'Paquetes/Listar', params:{id:empresa.id}}" class="btn-destok btn btn-primary" title="Paquetes y Precios" role="button">
                                Actualizar
                            </router-link>
                        </div>
                        <ul v-if="paquetes.length>0">
                            <li v-for="paquete in paquetes" :key="paquete.id">
                                {{paquete.nombre}} -- {{paquete.precio}}
                            </li>
                        </ul>
                        <p v-if="paquetes.length<=0">No hay paquetes registrados</p>
                        <router-link :to="{name:'Paquetes/Listar', params:{id:empresa.id}}" class="btn-movil btn btn-primary mt-5" title="Paquetes y Precios" role="button">
                            Actualizar
                        </router-link>
                        </b-overlay>
                    </div>

                    <hr>

                    <div id="redes-sociales" class="sociales">
                        <div class="title d-flex justify-content-between mb-3">
                            <h3>Redes Sociales</h3>
                            <b-button v-b-modal.modal-prevent-closing variant="link" class="btn-destok">+ Añadir redes sociales</b-button>
                        </div>
                        <ul v-if="empresa.redesSociales.length > 0 " class="list-group list-group-flush">
                            <li v-for="redSocial in empresa.redesSociales" :key="redSocial.id" class="d-flex list-group-item">
                                <p><i :class="redSocial.imagen" style="font-size:24px"></i> -- {{redSocial.nombre}} -- {{redSocial.pivot.valor}}</p>
                                <a class="text-danger ml-auto" title="Eliminar" role="button" v-on:click="eliminarRedSocial(redSocial.pivot.id)">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </li>
                        </ul>
                        <p v-if="empresa.redesSociales.length <= 0">No hay redes sociales registrados</p>
                        <b-button v-b-modal.modal-prevent-closing variant="link" class="btn-movil mt-3">+ Añadir redes sociales</b-button>
                    </div>

                    <!-- Modal-Social -->
                    <b-modal
                    id="modal-prevent-closing"
                    ref="modal"
                    title="Redes sociales"
                    @ok="handleOk"
                    >
                        <form>
                            <div class="form-group mt-3">
                                <label for="">Selecciona la red social que desea añadir</label>
                                <b-overlay :show="showSociales">
                                <select class="form-control form-select-sm" v-model="redSocial.id">
                                    <option v-for="social in redesSociales" :key="social.id" v-bind:value="social.id">
                                        {{social.nombre}}
                                    </option>
                                </select>
                                </b-overlay>
                                <small id="helpId" class="form-text text-muted">Seleccione la red social</small>
                            </div>
                            <div class="form-group mt-3">
                                <label for="">URL, dirección o teléfono</label>
                                <input type="text"
                                    class="form-control" required name="valor" v-model="redSocial.valor" value=" " id="valor" aria-describedby="helpId">
                                <small id="helpId" class="form-text text-muted">Introduce la url, dirección o teléfono de la red social</small>
                            </div>
                        </form>
                        
                    </b-modal>
                    <!-- Final-Modal -->
                
                </div>
            </div>
            <b-overlay :show="showGeneral" no-wrap></b-overlay>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {mapState} from 'vuex'
import {apiPublicada,apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            user: null, //Almacena los datos del usuario autenticado
            token: null, //Almacena los datos del token de seguridad
            empresa: null, //Almacena los datos de la empresa del usuario
            servicios: [], //Almacena los servicios registrados de la empresa
            paquetes:[], //ALmacena los paquetes y precios de la empresa
            redesSociales:[], //Almacenactodas las redes sociales registradas
            redSocial:{}, //Almacena los datos de los inputs para registrar una red social a la empresa
            showGeneral:true,
            showServicios:true,
            showPaquetes:true,
            showSociales:true,
            directorioImagen: this.$URLImagenes
        }
    },
    created: async function(){
        await this.obtenerUsuario()
        await this.consultarEmpresas()
        await this.consultarServicios()
        await this.consultarPaquetes()
        await this.consultarRedesSociales()
    },
    computed:{
        ...mapState({
            userAuth: 'user',
            tokenAuth: 'token'
        })
    },
    methods:{
        /**
         * Metodo para obtener los datos del usuario autenticado
         */
        obtenerUsuario(){
            return new Promise ((resolve,reject)=>{
                if(this.userAuth === null || this.token === null){
                    this.user = JSON.parse(sessionStorage.getItem('usuario'))
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.user = this.userAuth
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener la empresa del usuario que se encuentra registrada
         */
        async consultarEmpresas(){
            // return new Promise ((resolve,reject)=>{
            //     fetch('https://prixapi.prixisevents.com/api/empresas/usuario/'+this.user.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{
            //         if(respuestaDatos.res == '200'){
            //             this.empresa = respuestaDatos.msg[0]; 
            //             this.setIdEmpresa(this.empresa.id)
            //             this.showGeneral = false   
            //             resolve() 
            //         }else{
            //             console.log("Error al obtener la información de la empresa")
            //             resolve() 
            //         }
                                 
            //     })
            //     .catch(console.log)
            // })

            var data = await apiLocal.get('/empresas/usuario/'+this.user.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.empresa = res.msg[0]; 
                this.setIdEmpresa(this.empresa.id)
                this.showGeneral = false   
            }else{
                console.log("Error al obtener la información de la empresa")
            }
        },
        /**
         * Metodo para obtener los servicios de la empresa
         */
        async consultarServicios(){
            // return new Promise ((resolve,reject)=>{
            //     fetch('https://prixapi.prixisevents.com/api/empresas/servicios/' + this.empresa.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{ 
            //         if(respuestaDatos.res == '200'){
            //             this.servicios = respuestaDatos.msg; 
            //             this.showServicios = false                       
            //             resolve() 
            //         }else{
            //             console.log("Error al obtener los servicios de la empresa")
            //             resolve() 
            //         }       
                                   
            //     })
            //     .catch(console.log)
            // })

            var data = await apiLocal.get('/empresas/servicios/'+this.empresa.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.servicios = res.msg; 
                this.showServicios = false                       
            }else{
                console.log("Error al obtener los servicios de la empresa")
            }    
        },
        /**
         * Metodo para consultar paquetes y precios
         */
        async consultarPaquetes(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/paquetes/'+ this.empresa.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{
            //         if(respuestaDatos.res == '200'){
            //             this.paquetes = respuestaDatos.msg;
            //             this.showPaquetes = false
            //             resolve()
            //         }else{
            //             console.log("Error al obtener los paquetes de la empresa")
            //             resolve()
            //         }
                                      
            //     })
            //     .catch(console.log)           
            // })

            var data = await apiLocal.get('/paquetes/'+this.empresa.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.paquetes = res.msg;
                this.showPaquetes = false
            }else{
                console.log("Error al obtener los paquetes de la empresa")
            }
        },
        /**
         * Metodo para consultar todas las redes sociales
         */
        async consultarRedesSociales(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/redeSociales',{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{
            //         if(respuestaDatos.res == '200'){
            //             this.redesSociales = respuestaDatos.msg;
            //             this.showSociales = false
            //             resolve() 
            //         }else{
            //             console.log("Error al obtener las redes sociales registradas")
            //             resolve()
            //         }
                                           
            //     })
            //     .catch(console.log)           
            // })

            var data = await apiLocal.get('/redeSociales',{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.redesSociales = res.msg;
                this.showSociales = false
            }else{
                console.log("Error al obtener las redes sociales registradas")
            }
        },
        /**
         * Accion Aceptar del Modal
         */
        async handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()

        var socials = []
        socials.push(
            {
                id: this.redSocial.id,
                valor: this.redSocial.valor
            }
        )

        var datosEnviar = {
            redeSociales: socials
        }

        await this.addRedSocial(this.empresa.id, datosEnviar)
        await this.consultarEmpresas()

        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      /**
       * Añadir red social a la empresa
       */
        async addRedSocial(idEmpresa, datosEnviar){
        // return new Promise ((resolve,reject)=>{
        //     fetch('https://prixapi.prixisevents.com/api/empresas/redeSociales/' + idEmpresa,{
        //         method:"POST",
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer '+ this.token
        //         },
        //         body: JSON.stringify(datosEnviar)
        //     })
        //     .then(respuesta=>respuesta.json())
        //     .then(datosRespuesta=>{
        //         resolve()
        //     })
        //     .catch(error => console.error(error))
        // })

        var data = await apiLocal.post('/empresas/redeSociales/' + idEmpresa, datosEnviar,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ this.token
            }
        })
        var res = await data.data
      },
      /*
      *Metodo para eliminar red Solcial de la empresa 
      */
        async eliminarRedSocial(idSocialEmpresa){
            if(confirm('Estás seguro que deseas eliminar la red social de la empresa')) {

                // fetch('https://prixapi.prixisevents.com/api/empresas/redSocial/'+idSocialEmpresa,
                // {
                //     method:"DELETE",
                //     headers:{
                //         'Authorization': 'Bearer '+ this.token
                //     }
                // })
                // .then(respuesta=>respuesta.json())
                // .then((respuestaDatos)=>{
                //     this.consultarEmpresas()        
                // })
                // .catch(console.log)

                var data = await apiLocal.delete('/empresas/redSocial/'+idSocialEmpresa,{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                var res = await data.data
                this.consultarEmpresas()

            }      
        },
        /*
        *Metodo para eliminar red Solcial de la empresa 
        */
        eliminarEmpresa(idEmpresa){
            if(confirm('Estás seguro que deseas eliminar la empresa')) {

                fetch('https://prixapi.prixisevents.com/api/empresas/'+idEmpresa,
                {
                    method:"DELETE",
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.$router.replace({
                        name:'UserSimple'
                    })      
                })
                .catch(console.log)

            }      
        },
        ...mapMutations({
            setIdEmpresa: 'setIdEmpresa'
        })
    }
}
</script>

<style scoped>
/* seccion detalles */

.logo-empresa img{
    max-height: 250px;
    width: auto;
}

@media (max-width: 767px){
    .contenido{
        padding: 0 !important;
        margin: 0 !important;
    }
    .descripcion-empresa h3{
        text-align: center;
    }
    .descripcion-empresa p{
        text-align: center;
    }
}

@media (max-width: 767px){
    .detalles{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .descripcion{
        margin: 0 !important;
        text-align: center;
    }
}

/* seccion servicios */
.servicos .btn-movil{
    display: none;
}

@media (max-width: 767px){
    .options{
        display: none !important;
    }
    .servicos .btn-destock{
        display: none !important;
    }
    .servicos .btn-movil{
        display: block;
    }
    .servicos .title{
        text-align: center;
    }
    .servicos h3{
        width: 100%;
    }
    .servicos p{
        text-align: center;
    }
}

/* seccion paquetes y precios */
.paquetes .btn-movil{
    display: none;
}

@media (max-width: 767px){
    .paquetes .btn-destok{
        display: none !important;
    }
    .paquetes .btn-movil{
        display: block;
    }
    .paquetes .title{
        text-align: center;
    }
    .paquetes h3{
        width: 100%;
    }
    .paquetes p{
        text-align: center;
    }
}

/* seccion redes sociales */
#redes-sociales .btn-movil{
    display: none;
}

@media (max-width: 767px){
    #redes-sociales{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #redes-sociales .btn-destok{
        display: none !important;
    }
    #redes-sociales .btn-movil{
        display: block;
    }
    #redes-sociales .title{
        text-align: center;
    }
    #redes-sociales h3{
        width: 100%;
    }
    #redes-sociales p{
        text-align: center;
    }
}
</style>