import Vue from 'vue'
import Vuex from 'vuex'
import {apiPublicada,apiLocal} from "@/servicios/api.js"
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        user: null,
        token: null,
        hasEmpresa: false,
        idEmpresa: -1,
        pasoHome: false
    },
    getters:{},
    mutations:{
        setUser(state, user){
            state.user = user
        },

        setToken(state, token){
            state.token = token
        },

        setHasEmpresa(state, existe){
            state.hasEmpresa = existe
        },

        setIdEmpresa(state, idEmpresa){
            state.idEmpresa = idEmpresa
        },

        setPasoHome(state, paso){
            state.pasoHome = paso
        },

        logout(state){
            state.user = null
            state.token = null
            state.hasEmpresa = false
            state.idEmpresa = -1
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('usuario')
        },

    },
    actions:{
        logIn: async function({commit,dispatch}, credenciales){

            // var data = 
            // await fetch('https://prixapi.prixisevents.com/api/auth/login',{ //localhost/prixis/api-prixis
            //     method:"POST",
            //     headers: {
            //     'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(credenciales)
            // })
          
            // var res = await data.json()

            var data = await apiLocal.post('/auth/login',credenciales,{
                headers:{
                    'Content-Type': 'application/json'
                }
            })
            var res = await data.data

            if(res.res == 401){
                return res
            }
            else if(res.res == 200){
                var token = res.access_token
            
                return dispatch('obtenerUsuario',token)
            }
            else{
                res.message = "Ocurrio un error al intentar entrar al sistema, espere unos minutos e intente nuevamente"
                res.res = 403
                return res
            } 
        },

        obtenerUsuario: async function({commit}, token){
            sessionStorage.setItem('token',token)
            commit('setToken',token)

            if(sessionStorage.getItem('usuario') === null){
                // var data = 
                // await fetch('https://prixapi.prixisevents.com/api/auth/user',{ //localhost/prixis/api-prixis
                //     method:"GET",
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': 'Bearer '+token
                //     }
                // })
                // var res = await data.json()

                var data = await apiLocal.get('/auth/user',{
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+token
                    }
                })
                var res = await data.data

                var usuario = res
                usuario.rol = 'simple'
                sessionStorage.setItem('usuario',JSON.stringify(usuario))
                commit('setUser',usuario)
            }else{
                var usuario = JSON.parse(sessionStorage.getItem('usuario'))
                usuario.rol = 'simple'
                commit('setUser',usuario)
            }

            
        },

        existeEmpresa: async function({commit, state}, idUsuario){
            // var data = await fetch('https://prixapi.prixisevents.com/api/empresas/existe/' + idUsuario,{ //localhost/prixis/api-prixis
            //     headers:{
            //     'Authorization': 'Bearer '+state.token
            //     }
            // })
            // var red = await data.json()

            var data = await apiLocal.get('/empresas/existe/' + idUsuario,{
                headers:{
                    'Authorization': 'Bearer '+state.token
                }
            })
            var res = await data.data

            var existe = res.msg

            commit('setHasEmpresa',existe)
        }
    },
    modules:{}
})