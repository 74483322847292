<template>
    <div class="container pb-5 mb-5">
        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>
        
        <div class="card">
            <div class="card-body p-4">
                <div class="inicio-empresa">
                    <h3>Imagen de la sección de información de la empresa</h3>
                    <div class="contenido mt-4" v-if="empresa != {}">
                        <img id="img-about-empresa" :src="directorioImagen + empresa.imagen_inicio" alt="" class="">
                        
                        <div class="options ml-5 mt-3">
                            <p>Cambiar imagen</p>
                            <div class="custom-file mt-3">
                                <form v-on:submit.prevent="cambiarImgInicio"> 
                                    <label for="customFile" class="custom-file-label">Subir nueva imagen</label>
                                    <input class="custom-file-input" required type="file" id="customFile" name="file" @change="fileSelected">
                                    <figure v-if="imagenMiniatura != ''" class="mt-3">
                                        <img :src="this.imagenMiniatura" alt="" id="imagenPrevisualizar" class="bg-danger">
                                    </figure>

                                    <div class="btn-group mt-2 w-25" role="group" aria-label="">
                                        <button type="submit" class="btn btn-success">Cambiar</button>
                                    </div>
                                </form> 
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            empresa:{}, //Objeto que almacena los datos optenidos del metodo obtener Empresa.
            imgSobreEmpresa: null, //Almacena la imagen obtenida del select
            token: null, //Almacena los datos del token de seguridad
            imagenMiniatura:'', //Hace referencia a la previsualizacion de la imagen seleccionada
            newImgInicioEmpresa: '',
            directorioImagen: this.$URLImagenes,
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text: 'Actualizar',
                active: true
                }
            ]
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.obtenerEmpresa()
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener una empresa registrada
         */
        async obtenerEmpresa(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/empresas/' + this.$route.params.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{        
            //             this.empresa = respuestaDatos.msg;
            //             resolve()                
            //     })
            //     .catch(console.log)
            // })

            var data = await apiLocal.get('/empresas/'+ this.$route.params.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            this.empresa = res.msg;
        }, 
        /**
         * Metodo para cambiar la img de inicio de la empresa
         */
        async cambiarImgInicio(){
            var formData = new FormData()
            formData.append('imagen_inicio', this.newImgInicioEmpresa)
            
            // fetch('https://prixapi.prixisevents.com/api/empresas/personalizar/imgInicio/' + this.$route.params.id,
            // {
            //     method:"POST",
            //     headers: {
            //         'Content-Disposition': 'form-data',
            //         'Authorization': 'Bearer '+ this.token
            //     },
            //     body: formData
            // })
            // .then(respuesta=>respuesta.json())
            // .then(datosRespuesta=>{
            //     console.log('entro')
            //    this.newImgInicioEmpresa = ''
            //    this.imagenMiniatura = ''
            //    this.obtenerEmpresa()
            // })
            // .catch(error => {
            //  console.log(error)
            // })

            var data = await apiLocal.post('/empresas/personalizar/imgInicio/' + this.$route.params.id, formData,{
                headers:{
                    'Content-Disposition': 'form-data',
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            this.newImgInicioEmpresa = ''
            this.imagenMiniatura = ''
            this.obtenerEmpresa()
        },
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        async fileSelected(event){
            var imagen = event.target.files[0]

            var blob
            var blobMin

            // let reader = new FileReader()
            // reader.onload = (e) =>{
            //     this.imagenMiniatura = e.target.result
            // }
            // reader.readAsDataURL(this.empresa.imagen)

            blob = await this.comprimirImagen(imagen)
            blobMin = await this.comprimirImagenMiniaturas(imagen)
            this.imagenMiniatura = URL.createObjectURL(blobMin)
            this.newImgInicioEmpresa = blob
        },
        /**
         * Metodo para reducir el tamaño de las imagenes seleccionadas
         */
        comprimirImagen(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 560
                    var alto = 840

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
        comprimirImagenMiniaturas(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 141
                    var alto = 200

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
    },
    computed:{
        imagenM(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
    
}
</script>

<style scoped>
    #img-about-empresa{
        height: 400px;
        width: 283px;
    }
    .inicio-empresa .contenido{
        display: flex;
        flex-direction: row;
    }
    .options p{
        font-size: 24px;
    }

    @media (max-width: 767px){
        .inicio-empresa h3{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .inicio-empresa .contenido{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .options{
            margin-left: 0 !important;
        }
        .options p{
            text-align: center;
        }
    }
</style>