<template>
    <div>
        <nav class="">
        <div class="header">
            <div class="logo">
                <div class="logoimg">
                    <img src="../assets/img/cheng.jpg" alt="logo">
                </div>
                <div class="logotext text">
                    <span>Prixies</span>
                    <span>Desing by Chéng</span>
                </div>
            </div>   
        </div>
        
        <div class="user">
            <div class="userimg">
                <img src="../assets/img/user.jpg" alt="userimg">
            </div>
            <div class="username text">
                <span>Mario Sergio</span>
            </div>
        </div>

        <div class="mainbar">
            <div class="top">
                <ul>
                    <!-- <li>
                        <a href="">
                            <i class="fas fa-home icon"></i>                           
                            <span class="text">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fas fa-list icon"></i>
                            <span class="text">Project</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fas fa-comment icon"></i>
                            <span class="text">Chat</span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fas fa-bell icon"></i>
                            <span class="text">Notification</span>
                        </a>
                    </li> -->
                    <li v-if="user.rol != 'simple'">
                        <router-link to="/roles/listar">
                            <i class="fas fa-user-tag icon"></i>
                            <span class="text">Roles</span>
                        </router-link>
                    </li>
                    <li v-if="user.rol != 'simple'">
                        <router-link to="/usuarios/listar">
                            <i class="fas fa-user icon"></i>
                            <span class="text">Usuarios</span>
                        </router-link>
                    </li>
                    <!-- <li >
                        <router-link to="/galerias/listar">
                            <i class="fas fa-images icon"></i>
                            <span class="text">Galerias</span>
                        </router-link>
                    </li> -->
                    <li v-if="user.rol != 'simple'">
                        <router-link to="/empresas/listar">
                            <i class="fas fa-images icon"></i>
                            <span class="text">Empresas</span>
                        </router-link>
                    </li>
                    <li v-if="user.rol == 'simple'">
                        <router-link to="/empresas/principal">
                            <i class="fas fa-images icon"></i>
                            <span class="text">Empresa</span>
                        </router-link>
                    </li>
                    <li v-if="user.rol != 'simple'">
                        <router-link to="/tiposServicios/listar">
                            <i class="fas fa-images icon"></i>
                            <span class="text">Tipos de Servicios</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/servicios/listar">
                            <i class="fas fa-images icon"></i>
                            <span class="text">Servicios</span>
                        </router-link>
                    </li> -->
                </ul>
            </div>

            <div class="bottom">
                <li>
                    <a href="">
                        <i class="fas fa-cog icon"></i>
                        <span class="text">Settings</span>
                    </a>
                </li>
                <li v-on:click="logout()">
                    <a class="logout">
                        <i class="fas fa-sign-out-alt icon"></i>
                        <span class="text">Logout</span>
                    </a>
                </li>
            </div>           
        </div>       
    </nav>

    <!--TopMenu bar-->
    <div class="section">
        <div class="top_navbar">
            <div class="hamburger">
                <a v-on:click="desplazarMenu">
                    <i class="fas fa-bars"></i>
                </a>
            </div>
        </div>
    </div>
    </div> 
</template>

<script>
import {mapMutations} from 'vuex'

export default { 
    data(){
        return{
            user:null //Almacena al usuario autenticado
        }
    }, 
    created:function(){
        // this.obtenerUsuario()
    }, 
    methods:{
        desplazarMenu(){
            document.querySelector("nav").classList.toggle("closed");
            document.querySelector(".section").classList.toggle("expand");
            document.querySelector(".body-content").classList.toggle("body-content-extend");
            // console.log(document.querySelector(".body-content"))
        },
        logout(){
            this.cerrarSesion().then(()=>{
                this.$router.replace({
                    name:'AutenticarUsuario'
                })
            })    
        },
        obtenerUsuario(){
            this.user = JSON.parse(sessionStorage.getItem('user'))
            this.user.rol = 'simple'
        },
        ...mapMutations({
            cerrarSesion: 'logout',
        }),
    }
}
</script>

<style>
/* nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    background-color: white;
    padding: 12px;
    transition: all 0.5s ease;
}
 nav .logo{
     display: flex;
     align-items: center;
     padding: 5px 0px;
     margin-bottom: 10px;
 }

 nav .logoimg{
    min-width: 60px;
    display: flex;
    justify-content: center;
 }

 nav .logoimg img{
     width: 60px;
     border-radius: 8px;
 }

 nav .logotext{
     display: flex;
     flex-direction: column;
     font-weight: bold;
     font-size: 15px;
     color: black;
     white-space: nowrap;
     margin-left: 15px;
 }

 nav .logotext span:last-child{
     font-weight: 500;
 }

 nav .user{
     height: 50px;
     background-color: var(--secondcolor);
     margin-bottom: 10px;
     border-radius: 5px;
     display: flex;
     align-items: center;
 }

 nav .userimg{
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin: 5px;
     overflow: hidden;
 }

 nav .userimg img{
     max-width: 100%;
 }

 nav li{
     height: 50px;
     margin-bottom: 10px;
     display: flex;
     align-items: center;
 }

 nav li a{
     height: 100%;
     width: 100%;
     display: flex;
     align-items: center;
     text-decoration: none;
     color: #3c3c3c;
     border-radius: 5px;
     transition: all 0.3s;
 }

 nav li .icon{
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 nav li a:hover{
     background-color: var(--maincolor);
     color: white;
 }

 nav .mainbar{
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     height: calc(100% - 150px);
 }

 nav .logout{
     background-color: var(--secondcolor);
     color: red;
 }*/

 /*==========Top bar section============*/
 /* .section{
     width: calc(100% - 260px);
     margin-left: 260px;
     transition: all 0.5s ease;
 }

 .section .top_navbar{
     background-color: white;
     height: 50px;
     display: flex;
     align-items: center;
     padding: 0 30px;
 }

 .section .top_navbar .hamburger a{
     font-size: 28px;
     color: black;
 }

 .section .top_navbar .hamburger a:hover{
     color: var(--maincolor);
     cursor: pointer;
 }


 nav.closed{
     left: -260px;
 }

 .section.expand{
    margin-left: 0;
    width: 100%;
 }  */

</style>