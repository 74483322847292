<template>
    <div class="container pb-5">

        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>

        <div class="card mb-5 pb-4">
            <div class="card-header">
                Crear Servicio
            </div>
            <div class="card-body pb-5">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                        <label for="">Selecciona el tipo de servicio al que pertenece el servicio</label>
                        <b-overlay :show="showNomServ">
                        <select class="form-control form-select-sm" v-model="servicio.nombre">
                            <option v-for="nomServicio in listNombresServicios" :key="nomServicio.id" v-bind:value="nomServicio.id">
                                {{nomServicio.nombre}}
                            </option>
                        </select>
                        </b-overlay>
                        <small id="helpId" class="form-text text-muted">Seleccione el tipo de servicio</small>
                    </div> 

                    <div class="form-group mt-3">
                      <label for="">Descripción:</label> 
                      <textarea
                        class="form-control" required name="descripcion" v-model="servicio.descripcion" value=" " id="descripcion" aria-describedby="helpId" placeholder="Descripción">
                      </textarea>
                      <small id="helpId" class="form-text text-muted">Escribe una breve descripción sobre el servicio ofrecido</small>
                    </div>

                    <!-- <div class="form-group mt-3">
                        <label for="">Selecciona la empresa a la que corresponde el servicio</label>
                        <select class="form-control form-select-sm" v-model="servicio.id_empresa">
                            <option v-for="empresa in listEmpresas" :key="empresa.id" v-bind:value="empresa.id">
                                {{empresa.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione la empresa</small>
                    </div> -->

                    <div class="custom-file mt-3">
                        <label for="customFile" required class="custom-file-label">Subir imagen</label>
                        <input class="custom-file-input" type="file" id="customFile" name="file" @change="fileSelected">
                    </div> 

                    <!-- <figure class="mt-3" v-if="imagenMiniatura != ''">
                        <img alt="" :src="imagen" height="400" width="auto">
                    </figure> -->

                    <figure v-if="imagenMiniatura != ''" class="mt-3">
                        <img :src="this.imagenMiniatura" alt="" id="imagenPrevisualizar" class="bg-danger">
                    </figure>
                                                     
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link :to="{name:'Servicios/Listar', params:{id:this.$route.params.id}}" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'


export default {
    data(){
        return{
            token:null,
            servicio:{}, //Objeto que almacena los datos optenidos de los inputs
            listNombresServicios:[], //Lista que almacena los nombres de servicios registrados
            listEmpresas:[], //Lista que almacena las empresas registradas
            imagenMiniatura:'', //Hace referencia a la previsualizacion de la imagen seleccionada
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text:'Servicios',
                to: {name: 'Servicios/Listar', params:{id:this.$route.params.id}}
                },
                {
                text: 'Crear',
                active: true
                }
            ],
            showNomServ:true,

        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.obtenerNombresServicios()
        // await this.obtenerEmpresas()
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        async fileSelected(event){
            var imagen = event.target.files[0]

            var blob
            var blobMin

            // let reader = new FileReader()
            // reader.onload = (e) =>{
            //     this.imagenMiniatura = e.target.result
            // }
            // reader.readAsDataURL(this.servicio.imagen)

            blob = await this.comprimirImagen(imagen)
            blobMin = await this.comprimirImagenMiniaturas(imagen)
            this.imagenMiniatura = URL.createObjectURL(blobMin)
            this.servicio.imagen = blob
        },
         /**
       * Metodo para guardar un nuevo servicio
       */
        async agregarRegistro(){

            var formData = new FormData()
            formData.append('nombre', this.servicio.nombre)
            formData.append('descripcion', this.servicio.descripcion)
            formData.append('id_empresa', this.$route.params.id)
            formData.append('imagen', this.servicio.imagen)
            
            // fetch('https://prixapi.prixisevents.com/api/servicios',
            // {
            //     method:"POST",
            //     headers: {
            //     // 'Content-Type': 'application/json',
            //     'Authorization': 'Bearer '+ this.token
            //      },
            //     body: formData
            // })
            // .then(respuesta=>respuesta.json())
            // .then(datosRespuesta=>{
            //     if(datosRespuesta.res == '201'){
            //         this.servicio = {}
            //         window.location.href='/servicios/listar/' + this.$route.params.id
            //     }else if(datosRespuesta.res == '400'){
            //         console.log(datosRespuesta.msg)
            //     }else{
            //         console.log("Ocurrio un error a la hora de crear un nuevo servicio")
            //     }
                

            // })
            // .catch(error => {
            //  console.log(error)
            // })

            var data = await apiLocal.post('/servicios',formData,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '201'){
                this.servicio = {}
                window.location.href='/servicios/listar/' + this.$route.params.id
            }else if(res.res == '400'){
                console.log(res.msg)
            }else{
                console.log("Ocurrio un error a la hora de crear un nuevo servicio")
            }
        },
        /**
       * Metodo para obtener los tipos de servicios registrados
       */
        async obtenerNombresServicios(){
            var data = await apiLocal.get('/nombServicios',{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '200'){
                this.listNombresServicios = res.msg;
                this.showNomServ = false
            }else{
                console.log("Error al obtener los nombres de servicio registrados")
            }
        },
        /**
       * Metodo para obtener los tipos de servicios registrados
       */
       async obtenerEmpresas(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/empresas',{
            //         headers:{
            //         'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{
            //         this.listEmpresas = respuestaDatos;
            //         resolve()
            //     })
            //     .catch(console.log)            
            // })

            var data = await apiLocal.get('/empresas',{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            this.listEmpresas = res;
        },

        /**
         * Metodo para reducir el tamaño de las imagenes seleccionadas
         */
        comprimirImagen(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 283
                    var alto = 400

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
        comprimirImagenMiniaturas(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 283
                    var alto = 400

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
    },
    computed:{
        imagen(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
}
</script>