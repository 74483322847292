<template>
    <div class="container">

        <div class="card">
            <div class="card-header">
                Crear Usuario
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="usuario.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del usuario</small>
                    </div>            

                    <div class="form-group mt-3">
                      <label for="">Correo</label>
                      <input type="text"
                        class="form-control" required name="email" v-model="usuario.email" value=" " id="email" aria-describedby="helpId" placeholder="Correo">
                      <small id="helpId" class="form-text text-muted">Escribe el correo del usuario</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Contraseña</label>
                      <input type="password"
                        class="form-control" required name="password" v-model="usuario.password" value=" " id="password" aria-describedby="helpId" placeholder="Contraseña">
                      <small id="helpId" class="form-text text-muted">Escribe la contraseña del usuario</small>
                    </div>
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/usuarios/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            usuario:[] //Objeto que almacena los datos optenidos de los inputs
        }
    },
    methods:{
        /**
       * Metodo para guardar un nuevo usuario
       */
        agregarRegistro(){

            var datosEnviar = {
                nombre: this.usuario.nombre,
                password: this.usuario.password,
                email: this.usuario.email               
            }
            
            fetch('https://prixapi.prixisevents.com/api/usuarios',
            {
                method:"POST",
                headers: {
                'Content-Type': 'application/json',
                 },
                body: JSON.stringify(datosEnviar)
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
               this.usuario = {}
               window.location.href='/usuarios/listar'
            })
            .catch(error => {
             console.log(error)
            })
        },
    }
}
</script>