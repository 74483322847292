<template>
    <div class="container mb-5 pb-5">
        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>
        <h1>Galeria</h1>
        <div class="btn-group mt-2" role="group" aria-label="">
            <router-link :to="{name:'Servicios/Listar', params:{id:this.$route.params.idEmpresa}}" class="btn btn-danger">Volver Atrás</router-link>
            <router-link :to="{name:'GaleriasServicios/Crear', params:{idServicio:this.$route.params.idServicio, idEmpresa:this.$route.params.idEmpresa}}" class="btn btn-success">Añadir Imagen</router-link>
        </div>

        <div class="d-flex mt-3 flex-wrap">
            <figure v-for="imagen in imagenes" :key="imagen.id" class="mx-2">
                <img :src="directorioImagenes + imagen.url" alt="" id="imagenPrevisualizar" class="bg-danger">
                <div class="px-2 text-white" v-on:click="borrarImagen(imagen.id)">
                    <div class="d-inline-block rounded-bottom px-3 py-1 btn-delete">Eliminar</div>
                </div>
            </figure>
        </div>  
        <b-overlay :show="showGeneral"></b-overlay>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return{
            token:null,
            imagenes:[], //Lista que almacena las imagenes registradas
            directorioImagenes:'https://prixapi.prixisevents.com',
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text:'Servicios',
                to: {name: 'Servicios/Listar', params:{id:this.$route.params.idEmpresa}}
                },
                {
                text: 'Galería',
                active: true
                }
            ],
            showGeneral:true,
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.obtenerImagenes()
    },
    computed:{
        ...mapState({
            tokenAuth: 'token'
        })
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener las imageness registrados
         */
        obtenerImagenes(){
             return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/galeriaServicio/' + this.$route.params.idServicio,{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    if(typeof respuestaDatos){
                        if(respuestaDatos.res == '200'){
                            this.imagenes = respuestaDatos.msg; 
                            this.showGeneral = false 
                            resolve()
                        }else{
                            console.log("Error al obtener las imagenes de la galeria del servicio")
                            resolve()
                        }                       
                    }
                })
                .catch(console.log)      
            })
        },
        /**
         * Metodo para eliminar un servicio
         */
        borrarImagen(id){    
            if(confirm('Estás seguro que deseas eliminar la imagen')) {

                fetch('https://prixapi.prixisevents.com/api/galeriaServicio/'+id,
                {
                    method:"DELETE",
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    if(respuestaDatos.res == '200'){
                        window.location.href='/galeriaServicios/' + this.$route.params.idServicio + '/' + this.$route.params.idEmpresa
                    }else{
                        console.log("Error al intentar eliminar la imagen")
                    }
                })
                .catch(console.log)

            }              
        },
    }
}
</script>

<style scoped>
.btn-delete{
    background-color: crimson;
}
.btn-delete:hover{
    background-color: rgb(165, 0, 33);
    cursor: pointer;
}
</style>