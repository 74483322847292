<template>
    <div class="container pb-5">

        <div class="d-flex justify-content-between">
            <div>
                <template>
                    <b-breadcrumb :items="links"></b-breadcrumb>
                </template>
            </div>
            <div>
            </div>
        </div>

        <div class="card mb-5 pb-4">
            <div class="card-header">
                Actualizar Empresa
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="empresa.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre de la empresa</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Descripción:</label> 
                      <textarea
                        class="form-control" required name="nombre" v-model="empresa.descripcion" value=" " id="nombre" aria-describedby="helpId" placeholder="Descripción">
                      </textarea>
                      <small id="helpId" class="form-text text-muted">Escribe una breve descripción sobre lo que trata la empresa</small>
                    </div>

                    <!-- Poner que en la descripcion como maximo hayan 1200 caracteres -->

                    <div class="form-group mt-3">
                      <label for="">Teléfono:</label>
                      <input type="text"
                        class="form-control" name="telefono" v-model="empresa.telefono" value=" " id="telefono" aria-describedby="helpId" placeholder="Teléfono">
                      <small id="helpId" class="form-text text-muted">Escribe el teléfono de la empresa</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Correo:</label>
                      <input type="text"
                        class="form-control" name="correo" v-model="empresa.correo" value=" " id="correo" aria-describedby="helpId" placeholder="Correo">
                      <small id="helpId" class="form-text text-muted">Escribe el correo de la empresa</small>
                    </div>

                    <div class="custom-file mt-3">
                        <label for="customFile" required class="custom-file-label">Subir imagen</label>
                        <input class="custom-file-input" type="file" id="customFile" name="file" @change="fileSelected">
                    </div> 

                    <!-- <figure class="mt-3" v-if="imagenMiniatura != ''">
                        <img alt="" width="100" :src="imagen" height="100">
                    </figure>                        -->

                    <figure v-if="imagenMiniatura != ''" class="mt-3">
                        <img :src="this.imagenMiniatura" alt="" id="imagenPrevisualizar" class="bg-danger">
                    </figure>
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/empresas/principal" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {apiLocal} from "@/servicios/api.js"
import axios from 'axios'

export default {
    data(){
        return{
            empresa:{}, //Objeto que almacena los datos optenidos de los inputs
            imagenMiniatura:'', //Hace referencia a la previsualizacion de la imagen seleccionada
            user:null, //Id del usuario autenticado
            token: null, //Almacena los datos del token de seguridad
            directorioImagenes: this.$URLImagenes,
            selectImg: false, //Indica si se selecciono una nueva imagen
            links:[      //Links del breadcrumb
                {
                text: 'Empresa',
                to: { name: 'Empresas/Principal' }
                },
                {
                text: 'Actualizar',
                active: true
                }
            ]
        }
    },
    created: async function(){
        await this.obtenerUsuario()
        await this.obtenerEmpresa()
    },
    methods:{
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        async fileSelected(event){
            this.selectImg = true
            var imagen = event.target.files[0]

            var blob
            var blobMin

            blob = await this.comprimirImagen(imagen)
            blobMin = await this.comprimirImagenMiniaturas(imagen)
            this.imagenMiniatura = URL.createObjectURL(blobMin)
            this.empresa.imagen = blob
        },
        /**
       * Metodo para guardar una nueva EMPRESA
       */
        async agregarRegistro(){

            var formData = new FormData()
            formData.append('nombre', this.empresa.nombre)
            formData.append('descripcion', this.empresa.descripcion)
            formData.append('telefono', this.empresa.telefono)
            formData.append('correo', this.empresa.correo)
            formData.append('id_director', this.user.id)

            if(this.selectImg){
                formData.append('imagen', this.empresa.imagen)
            }
            
            // fetch('https://prixapi.prixisevents.com/api/empresa/' + this.$route.params.id,
            // {
            //     method:"POST",
            //     headers: {
            //         'Content-Disposition': 'form-data',
            //         'Authorization': 'Bearer '+ this.token
            //     },
            //     body: formData
            // })
            // .then(respuesta=>respuesta.json())
            // .then(datosRespuesta=>{
               

            //     if(datosRespuesta.res == '201'){
            //         this.empresa = {}
            //         this.$router.replace({
            //             name:'Empresas/Principal'
            //         })
            //     }else if(datosRespuesta.res == '400'){
            //         console.log(datosRespuesta.msg)
            //     }else{
            //         console.log("Ocurrio un error a la hora de actualizar los datos de la empresa")
            //     }
            // })
            // .catch(error => {
            //  console.log(error)
            // })

            var data = await apiLocal.post('/empresa/' + this.$route.params.id, formData,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            if(res.res == '201'){
                this.empresa = {}
                this.$router.replace({
                    name:'Empresas/Principal'
                })
            }else if(res.res == '400'){
                console.log(res.msg)
            }else{
                console.log("Ocurrio un error a la hora de actualizar los datos de la empresa")
            }
        },
        obtenerUsuario(){
            return new Promise ((resolve,reject)=>{
                if(this.userAuth === null || this.tokenAuth === null){
                    this.user = JSON.parse(sessionStorage.getItem('usuario'))
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.user = this.userAuth
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener una empresa registrada
         */
        async obtenerEmpresa(){
            // return new Promise ((resolve,reject)=>{

            //     fetch('https://prixapi.prixisevents.com/api/empresas/' + this.$route.params.id,{
            //         headers:{
            //             'Authorization': 'Bearer '+ this.token
            //         }
            //     })
            //     .then(respuesta=>respuesta.json())
            //     .then((respuestaDatos)=>{        
            //             this.empresa = respuestaDatos.msg;
            //             this.imagenMiniatura = this.directorioImagenes + this.empresa.imagen
            //             resolve()                
            //     })
            //     .catch(console.log)
            // })

            var data = await apiLocal.get('/empresas/'+ this.$route.params.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            var res = await data.data

            this.empresa = res.msg;
            this.imagenMiniatura = this.directorioImagenes + this.empresa.imagen
        }, 

        /**
         * Metodo para reducir el tamaño de las imagenes seleccionadas
         */
        comprimirImagen(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 0
                    var alto = 0

                    if(imagen.height > imagen.width){
                        ancho = 283
                        alto = 400
                    }else if(imagen.height < imagen.width){
                        ancho = 400 //400
                        alto = 283 //283
                    }else{
                        ancho = 250 //250
                        alto = 250
                    }

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
        comprimirImagenMiniaturas(imgArchivo){
            return new Promise((resolve, reject) => {

                const $canvas = document.createElement("canvas");
                const imagen = new Image();

                imagen.onload = () => {
                    var ancho = 0
                    var alto = 0

                    if(imagen.height > imagen.width){
                        ancho = 141
                        alto = 200
                    }else if(imagen.height < imagen.width){
                        ancho = 200
                        alto = 141
                    }else{
                        ancho = 125
                        alto = 125
                    }

                    $canvas.width = ancho
                    $canvas.height = alto

                    
                    $canvas.getContext("2d").drawImage(imagen, 0, 0, ancho, alto);
                    $canvas.toBlob(
                        (blob) => {
                            if (blob === null) {
                                return reject(blob);
                            } else {
                                resolve(blob);
                            }
                        },
                        "image/jpeg",
                        1
                    )
                }
                imagen.src = URL.createObjectURL(imgArchivo);
            })
        },
    },
    computed:{
        imagen(){
            return this.imagenMiniatura
        },
        ...mapState({
            userAuth: 'user',
            tokenAuth: 'token'
        })
    }
}
</script>