<template>
    <div class="container">
        
        <router-link to="/tiposServicios/crear" class="btn btn-success">Crear nuevo</router-link>

        <div class="card mt-3">
            <div class="card-header">
                Tipos de Servicios
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                                                   
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="tipoServicio in tiposServicios" :key="tipoServicio.id">                         
                            <td> {{tipoServicio.nombre}} </td>                                               

                            <td>          
                                <router-link :to="{name:'TiposServicios/Editar', params:{id:tipoServicio.id}}" class="text-primary" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a class="text-danger ml-2" v-on:click="borrarTipoServicio(tipoServicio.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return{
            tiposServicios:[], //Lista que almacena los tipos de servicios optenidos del metodo "consultarTiposServicios"
            token: null, //Almacena los datos del token de seguridad
        }
    },
    created: async function(){
        await this.obtenerToken()
        await this.consultarTiposServicios();
    },
    methods:{
        /**
         * Metodo para obtener el token de seguridad
         */
        obtenerToken(){
            return new Promise ((resolve,reject)=>{
                if(this.tokenAuth === null){
                    this.token = sessionStorage.getItem('token')
                    resolve()
                }else{
                    this.token = this.tokenAuth
                    resolve()
                }
            })
        },
        /**
         * Metodo para obtener los tipos de servicios registrados
         */
        consultarTiposServicios(){
            return new Promise ((resolve,reject)=>{
                fetch('https://prixapi.prixisevents.com/api/nombServicios',{
                    headers:{
                        'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                    this.tiposServicios = respuestaDatos; 
                    console.log(this.tiposServicios)
                    resolve()
                })
                .catch(console.log)
            })
        },

        /**
         * Metodo para eliminar un tipo de servicio
         */
        borrarTipoServicio(id){    
            if(confirm('Estás seguro que deseas eliminar el tipo de servicio')) {

                fetch('https://prixapi.prixisevents.com/api/tiposservicios/'+id,
                {
                    method:"DELETE",
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    window.location.href='/tiposServicios/listar'      
                })
                .catch(console.log)

            }              
        },
    },
    computed:{
        imagenM(){
            return this.imagenMiniatura
        },
        ...mapState({
            tokenAuth: 'token'
        })
    }
}
</script>