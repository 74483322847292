<template>
  <div>
    <!-- <SideBarMenu/> -->
    <TopBar/>
    <section class="body-content">
      <div class="container mt-5">
        <router-view/>
      </div>    
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBarMenu from '../components/SideBarMenu.vue'
import TopBar from '../components/TopBar.vue'
import {mapState} from 'vuex'
import {mapMutations} from 'vuex'
import {mapActions} from 'vuex'


export default {
  name: 'HomeView',
  components: {
    SideBarMenu, TopBar
  },
  data(){
    return{
      user:null,
      useRol:'', //Almacena el rol del usuario 
    }
  },
  created: async function(){
    this.user = this.userAuth
    if(this.user === null){
      await this.obtenerUsuario()
    }else{
      if(window.location.href == this.$baseURL){  //https://empresapanel.prixisevents.com/
        this.lanzarVista()
      }
    }
  },
  computed:{
      ...mapState({
          hasEmpresa: 'hasEmpresa',
          paso: 'pasoHome',
          userAuth: 'user',
          token: 'token'
      })
  },
  methods:{
    lanzarVista(){
      this.existEmp(this.user.id).then(()=>{
        this.$router.replace({
          name:'UserSimple'
        })
      }) 
    },
     /**
       * Metodo para obtener los datos del usuario autenticado
       */
      obtenerUsuario(){
        return new Promise ((resolve,reject)=>{
            this.user = JSON.parse(sessionStorage.getItem('usuario'))
        })
      },
    ...mapMutations({
      setPasoHome: 'setPasoHome',
    }),
    ...mapActions({
      existEmp: 'existeEmpresa',
      getUserAuth: 'obtenerUsuario'
    }),
  }
}
</script>
