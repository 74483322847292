<template>
    <div class="container">
       
        <div class="card">
            <div class="card-header">
                Modificar Galeria
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="actualizarRegistro">
          
                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="galeria.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre de la galeria</small>
                    </div>

                    <div class="btn-group" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Modificar</button>
                        <router-link to="/galerias/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            galeria:{} //Objeto que almacena la galeria obtenida del metodo "obtenerGaleria"
        }
    },
    created: async function(){
        await this.obtenerGaleria()    
    },
    methods:{
        /**
         * Metodo para obtener una galeria registrada
         */
        obtenerGaleria(){
             return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/galerias/' + this.$route.params.id)
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                        this.galeria = respuestaDatos;                        
                        resolve()                
                })
                .catch(console.log)
            })
        }, 
    }
}
</script>