<template>
    <div class="container">
       
        <div class="card">
            <div class="card-header">
                Modificar usuarios
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="actualizarRegistro">
          
                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="usuario.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre del usuario</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Correo</label>
                      <input type="text"
                        class="form-control" required name="email" v-model="usuario.email" value=" " id="email" aria-describedby="helpId" placeholder="Correo">
                      <small id="helpId" class="form-text text-muted">Escribe el correo del usuario</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Contraseña</label>
                      <input type="password"
                        class="form-control" required name="password" v-model="usuario.password" value=" " id="password" aria-describedby="helpId" placeholder="Contraseña">
                      <small id="helpId" class="form-text text-muted">Escribe la contraseña del usuario</small>
                    </div>

                    <div class="btn-group" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Modificar</button>
                        <router-link to="/usuarios/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            usuario:[] //Objeto que almacena el usuario obtenido del metodo "obtenerUsuario"
        }
    },
    created:function(){
        this.obtenerUsuario()
    },
    methods:{
        /**
         * Metodo para obtener un usuario registrado
         */
        obtenerUsuario(){
           return new Promise ((resolve,reject)=>{
                console.log(this.$route.params.id)
                fetch('https://prixapi.prixisevents.com/api/usuarios/'+this.$route.params.id) //http://prixisapi.net/usuarios/
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.usuario = respuestaDatos.msg; 
                    resolve();
                })
                .catch(console.log)
            })
        },
        /**
         * Metodo para actualizar los datos de un usuario
         */
        actualizarRegistro(){
            var datosEnviar = {
                id: this.usuario.id,
                nombre: this.usuario.nombre,
                password: this.usuario.password,
                email: this.usuario.email
            }

            fetch('https://prixapi.prixisevents.com/api/usuarios/'+datosEnviar.id, //http://prixisapi.net/usuarios/
            {
                method:"PUT",
                headers: {
                'Content-Type': 'application/json',
                 },
                body: JSON.stringify(datosEnviar)
            })
            .then(datosRespuesta=>{              
            //    window.location.href='/usuarios/listar'
            console.log(datosRespuesta)
            })
            .catch(error => console.error(error))
        },
    }
}
</script>