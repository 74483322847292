<template>
    <div class="container pb-5">

        <div class="card mb-5 pb-4">
            <div class="card-header">
                Crear Empresa
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="empresa.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre de la empresa</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Descripción:</label> 
                      <textarea
                        class="form-control" required name="nombre" v-model="empresa.descripcion" value=" " id="nombre" aria-describedby="helpId" placeholder="Descripción">
                      </textarea>
                      <small id="helpId" class="form-text text-muted">Escribe una breve descripción sobre lo que trata la empresa</small>
                    </div>

                    <!-- Poner que en la descripcion como maximo hayan 1200 caracteres -->

                    <div class="form-group mt-3">
                      <label for="">Teléfono:</label>
                      <input type="text"
                        class="form-control" name="telefono" v-model="empresa.telefono" value=" " id="telefono" aria-describedby="helpId" placeholder="Teléfono">
                      <small id="helpId" class="form-text text-muted">Escribe el teléfono de la empresa</small>
                    </div>

                    <div class="form-group mt-3">
                      <label for="">Correo:</label>
                      <input type="text"
                        class="form-control" name="correo" v-model="empresa.correo" value=" " id="correo" aria-describedby="helpId" placeholder="Correo">
                      <small id="helpId" class="form-text text-muted">Escribe el correo de la empresa</small>
                    </div>

                    <div class="form-group mt-3">
                        <label for="">Selecciona el director de la empresa</label>
                        <select class="form-control form-select-sm" v-model="idDirector">
                            <option v-for="usuario in listUsuarios" :key="usuario.id" v-bind:value="usuario.id">
                                {{usuario.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione el fotografo</small>
                    </div>

                    <div class="custom-file mt-3">
                        <label for="customFile" required class="custom-file-label">Subir imagen</label>
                        <input class="custom-file-input" type="file" id="customFile" name="file" @change="fileSelected">
                    </div> 

                    <figure class="mt-3" v-if="imagenMiniatura != ''">
                        <img alt="" width="100" :src="imagen" height="100">
                    </figure>

                    <div class="border mt-3 p-4">
                        <p>Redes Sociales</p>
                        <div class="form-group mt-3" v-for="(redSocialS, index) in redeSocialesSelec" :key="redSocialS.id">
                        <label for="">{{redSocialS.nombre}}</label>
                        <input type="text"
                            class="form-control" required name="nombre" v-model="socialList[index]" value=" " id="nombre" aria-describedby="helpId" placeholder="Introduce la url, dirección o teléfono de la red social">
                        </div>

                        <!-- Modal -->

                        <b-button v-b-modal.modal-prevent-closing variant="link" class="mt-3">+ Añadir redes sociales</b-button>

                        <b-modal
                        id="modal-prevent-closing"
                        ref="modal"
                        title="Redes sociales"
                        @ok="handleOk"
                        >
                            <b-form-group label="Seleccione las redes que emplea la empresa:" v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group
                                id="checkbox-group-1"
                                v-model="selectedModal"
                                :options="redeSociales"
                                :aria-describedby="ariaDescribedby"
                                value-field="id"
                                text-field="nombre"
                                name="flavour-1"
                            ></b-form-checkbox-group>
                            </b-form-group>
                        </b-modal>

                        <!-- Final-Modal -->
                    </div>                          
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/empresas/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            empresa:{}, //Objeto que almacena los datos optenidos de los inputs
            listUsuarios:[], //Lista que almacena los usuarios registrados
            idDirector:0, //Almacena el id del director del select
            redeSocialesSelec:[], //Lista que almacena las redes sociales seleccionadas
            redeSociales:[], //Lista que almacena las redes sociales registradas
            selectedModal:[],
            socialList:[],
            imagenMiniatura:'' //Hace referencia a la previsualizacion de la imagen seleccionada
        }
    },
    created: async function(){
        await this.obtenerUsuarios()
        await this.obtenerRedesSociales()
    },
    methods:{
        /**
       * Metodo para guardar la imagen seleccionada en la variable imagen
       */
        fileSelected(event){
            this.empresa.imagen = event.target.files[0]

            let reader = new FileReader()
            reader.onload = (e) =>{
                this.imagenMiniatura = e.target.result
            }
            reader.readAsDataURL(this.empresa.imagen)
        },
        /**
       * Metodo para guardar una nueva EMPRESA
       */
        agregarRegistro(){

            var formData = new FormData()
            formData.append('nombre', this.empresa.nombre)
            formData.append('descripcion', this.empresa.descripcion)
            formData.append('telefono', this.empresa.telefono)
            formData.append('correo', this.empresa.correo)
            formData.append('id_director', this.idDirector)
            formData.append('imagen', this.empresa.imagen)
            
            fetch('https://prixapi.prixisevents.com/api/empresas', //http://prixisapi.net/empresas
            {
                method:"POST",
                headers: {
                // 'Content-Type': 'application/json',
                },
                body: formData
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
               this.empresa = {}
               console.log(datosRespuesta.msg)
            //    window.location.href='/empresas/listar'
               this.agregarRedesSociales(datosRespuesta.emp)  

            })
            .catch(error => {
             console.log(error)
            })
        },

        /**
         * Metodo para agregar las redes sociales a la empresa
         */
        agregarRedesSociales(idEmpresa)
        {
            var socials = []

            for(var i=0; i<this.socialList.length; i++){
                socials.push(
                    {
                        id: this.redeSocialesSelec[i].id,
                        valor: this.socialList[i]
                    }
                )
            }

            var datosEnviar = {
                redeSociales: socials
            }

            fetch('https://prixapi.prixisevents.com/api/empresas/redeSociales/'+idEmpresa,{
                method:"POST",
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(datosEnviar)
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
            
            console.log(datosRespuesta.msg)
            window.location.href='/empresas/listar'
            })
            .catch(error => console.error(error))
        },
       /**
       * Metodo para obtener los usuarios registrados
       */
       obtenerUsuarios(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/usuarios')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.listUsuarios = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },
        /**
       * Metodo para obtener las redes sociales registradas
       */
       obtenerRedesSociales(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/redeSociales')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.redeSociales = respuestaDatos;
                    console.log(this.redeSociales)
                    resolve()
                })
                .catch(console.log)            
            })
        },
        /**
         * Accion Aceptar del Modal
         */
        handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()

        this.redeSocialesSelec = []
        
        for(var i=0; i<this.selectedModal.length; i++){
            for(var j=0; j<this.redeSociales.length; j++){
                if(this.selectedModal[i]==this.redeSociales[j].id){
                    var social = {
                        id: this.selectedModal[i],
                        nombre: this.redeSociales[j].nombre
                    }
                    this.redeSocialesSelec.push(social)
                }
            }
        }

        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
    },
    computed:{
        imagen(){
            return this.imagenMiniatura
        }
    }
}
</script>