<template>
    <div class="container">
        
        <router-link to="/galerias/crear" class="btn btn-success">Crear nuevo</router-link>

        <div class="card mt-3">
            <div class="card-header">
                Galerias
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>                           
                            <th>Nombre</th>                         
                            <th>Usuario</th> 
                            <th>Fotógrafo</th>                          
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="galeria in galerias" :key="galeria.id">                         
                            <td> {{galeria.nombre}} </td>                        
                            <td> {{galeria.usuario.nombre}} </td>  
                            <td> {{galeria.fotografo.nombre}} </td>                       

                            <td>          
                                <router-link :to="{name:'Galerias/Detalles', params:{id:galeria.id}}" class="text-primary" title="Detalles" role="button">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <router-link :to="{name:'Galerias/Editar', params:{id:galeria.id}}" class="text-primary ms-2" title="Editar" role="button">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <a class="text-danger ms-2" v-on:click="borrarGaleria(galeria.id)" title="Eliminar" role="button">
                                    <i class="fas fa-trash"></i>
                                </a> 
                            </td>
                        </tr>

                    </tbody> 
                </table>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    data(){
        return{
            galerias:[] //Lista que almacena las galerias optenidas del metodo "consultarGalerias"
        }
    },
    created:function(){
        this.consultarGalerias();
    },
    methods:{
        /**
         * Metodo para obtener las galerias registradas
         */
        consultarGalerias(){
            fetch('https://prixapi.prixisevents.com/api/galerias')
            .then(respuesta=>respuesta.json())
            .then((respuestaDatos)=>{
                if(typeof respuestaDatos[0].success==='undefined'){
                    this.galerias = respuestaDatos;                  
                }
            })
            .catch(console.log)
        },

        /**
         * Metodo para eliminar una galeria
         */
        borrarGaleria(id){    
            if(confirm('Estás seguro que deseas eliminar la galeria')) {

                fetch('https://prixapi.prixisevents.com/api/galerias/'+id,
                {
                    method:"DELETE",
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    window.location.href='/galerias/listar'      
                })
                .catch(console.log)

            }              
        },
    }
}
</script>