<template>
    <div class="container">

        <div class="card">
            <div class="card-header">
                Crear Galeria
            </div>
            <div class="card-body">
                <form v-on:submit.prevent="agregarRegistro">

                    <div class="form-group mt-3">
                      <label for="">Nombre:</label>
                      <input type="text"
                        class="form-control" required name="nombre" v-model="galeria.nombre" value=" " id="nombre" aria-describedby="helpId" placeholder="Nombre">
                      <small id="helpId" class="form-text text-muted">Escribe el nombre de la galeria</small>
                    </div>    

                    <div class="form-group mt-3">
                        <label for="">Selecciona el fotografo</label>
                        <select class="form-select form-select-sm" v-model="idFotografo">
                            <option v-for="fotografo in lisFotografos" :key="fotografo.id" v-bind:value="fotografo.id">
                                {{fotografo.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione el fotografo</small>
                    </div>   

                    <div class="form-group mt-3">
                        <label for="">Selecciona el cliente</label>
                        <select class="form-select form-select-sm" v-model="idCliente">
                            <option v-for="cliente in lisClientes" :key="cliente.id" v-bind:value="cliente.id">
                                {{cliente.nombre}}
                            </option>
                        </select>
                        <small id="helpId" class="form-text text-muted">Seleccione el cliente</small>
                    </div>       
           
                    <div class="btn-group mt-4" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Agregar</button>
                        <router-link to="/galerias/listar" class="btn btn-danger">Cancelar</router-link>
                    </div>

                </form>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            galeria:{}, //Objeto que almacena los datos obtenidos de los inputs
            lisFotografos:[], //Lista que almacena los fotografos registrados
            lisClientes:[], //Lista que almacena los clientes registrados
            idFotografo:0, //Almacena el id del fotografo del select
            idCliente:0 //Almacena el id del cliente del select
        }
    },
    created: async function(){
        await this.obtenerFotografos()
        await this.obtenerClientes()
    },
    methods:{
       /**
       * Metodo para guardar una nueva galeria
       */
        agregarRegistro(){

            var datosEnviar = {
                nombre: this.galeria.nombre,
                id_usuario: this.idCliente,
                id_fotografo: this.idFotografo           
            }
            
            fetch('https://prixapi.prixisevents.com/api/galerias',
            {
                method:"POST",
                headers: {
                'Content-Type': 'application/json',
                 },
                body: JSON.stringify(datosEnviar)
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
               this.galeria = {}
               window.location.href='/galerias/listar'
            })
            .catch(error => {
             console.log(error)
            })
        },

       /**
       * Metodo para obtener los fotografos registrados
       */
       obtenerFotografos(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/usuarios/fotografos')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.lisFotografos = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },

        /**
       * Metodo para obtener los clientes registrados
       */
       obtenerClientes(){
          return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/usuarios/clientesFotografos')
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{
                    this.lisClientes = respuestaDatos;
                    resolve()
                })
                .catch(console.log)            
            })
        },
    }    
}
</script>