<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  beforeCreate: async function(){
    if(sessionStorage.getItem('token') === null || sessionStorage.getItem('usuario') === null){
      this.$router.replace({
        name:'AutenticarUsuario'
      })
    }
  },

  created: async function(){
    await this.obtenerUsuario()
  },

  methods:{
    ...mapActions({
      getAuthUser: 'obtenerUsuario'
    }),
    obtenerUsuario(){
      return new Promise ((resolve,reject)=>{
        var token = sessionStorage.getItem('token')
        this.getAuthUser(token).then(()=>{
          resolve()
        })
      })
    }
  }
}
</script>

<style>
:root{
  --maincolor: #2a7dff;
  --secondcolor: #eff3fb;
}

body{
  background-color: var(--secondcolor);
  margin: 0;
  padding: 0;
}

#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

/*.body-content{
  width: calc(100% - 300px);
  margin-left: 300px;
}*/
</style>
